import React, { forwardRef, useImperativeHandle, useEffect, useRef, useCallback } from 'react';
import OpenSeadragon from 'openseadragon';

const OpenSeadragonViewer = forwardRef(({ tileSources, onClick, landmarks, onViewerReady, onRotationChange, showRotationControl }, ref) => {
  const viewerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getViewer: () => viewerRef.current?.openSeadragonViewer,
  }));


  useEffect(() => {
    console.log('Initializing or updating OpenSeadragon viewer');
    if (viewerRef.current) {
      const viewer = OpenSeadragon({
        element: viewerRef.current,
        prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        tileSources,
        minZoomLevel: 0.5, // Minimum zoom level
        maxZoomLevel: 10, // Maximum zoom level, increase as needed
        navigationControlAnchor: OpenSeadragon.ControlAnchor.TOP_RIGHT,
        // Show rotation buttons
        showRotationControl: showRotationControl || false,
        clickToZoom: false,
        gestureSettingsMouse: {
          clickToZoom: false,        // Disable left-click zoom
          dblClickToZoom: false,     // Disable double-click zoom
          dragToPan: false,          // Disable left-click panning
          dragToPanNonPrimary: true, // Enable right-click panning
          zoomToRefPoint: false,     // Disable zoom at reference point
        },
      });

      // Attach the canvas-click event listener to handle clicks
      viewer.addHandler('canvas-click', function(event) {
        console.log('Canvas clicked', event); // Debugging line
        // Prevent default click action
        event.preventDefaultAction = true;
        // Convert click position to viewport coordinates
        const viewportPoint = viewer.viewport.pointFromPixel(event.position);
        // Then convert to image coordinates
        const imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint);
          
        // }, { once: true }); // Ensure this listener is executed at most once
        // Call onClick prop function with image coordinates
        // Extend the logic to check if we're in "adding landmarks" mode
        if (onClick) {
          console.log("Onclick invoked")
          // Call onClick only if we're adding a landmark
          onClick({ x: imagePoint.x, y: imagePoint.y });
        }
      });

      // Adding the rotation handler function
      viewer.addHandler('rotate', function(event) {
        const rotation = viewer.viewport.getRotation();
        console.log('Rotation changed:', rotation);

        // Call a prop function or directly send to backend API
        if (onRotationChange) {
          onRotationChange(rotation);
        }
      });

      var dragPosition; 
      var nonPrimaryDown;

      // Need to prevent the context menu or it'll interfere with dragging.
      viewer.canvas.addEventListener('contextmenu', function(event) {
          event.preventDefault();
      });

      viewer.addHandler('canvas-nonprimary-press', function(event) { // The event parameter was missing here
          console.log('nonprimary press');
          nonPrimaryDown = true;
          dragPosition = event.position.clone();
      });

      viewer.addHandler('canvas-nonprimary-release', function() {
          console.log('nonprimary release');
          nonPrimaryDown = false;
      });

      new OpenSeadragon.MouseTracker({
          element: viewer.canvas,
          moveHandler: function(event) {
              if (nonPrimaryDown) {
                  console.log('nonprimary drag', event);
                  var delta = event.position.minus(dragPosition);
                  viewer.viewport.panBy(viewer.viewport.deltaPointsFromPixels(delta.negate()));
                  dragPosition = event.position.clone(); // This line was missing
              }
          }
      });

      viewer.addHandler('open', () => {
        console.log('Viewer initialized with new tile source');
        if (onViewerReady) {
          onViewerReady(true);  // Notify parent component that the viewer is ready
        }
      })

      // Store the viewer in the ref for cleanup
      viewerRef.current.openSeadragonViewer = viewer;

      return () => viewer.destroy();
    }
  }, [tileSources]);

  return <div ref={viewerRef} style={{ width: '100%', height: '96%', backgroundColor: 'black' }}></div>;
});

export { OpenSeadragonViewer as OpenSeadragonPrimaryViewer };
export { OpenSeadragonViewer as OpenSeadragonSecondaryViewer };


