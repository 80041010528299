// ImportModal.jsx

import React, { useState } from 'react';
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Button,
  Table,
  LinearProgress,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IosShareIcon from '@mui/icons-material/IosShare';

function ImportModal(props) {
  // Destructure props
  const {
    modalOpen,
    handleCloseModal,
    projects,
    datasets,
    importedSamples,
    isLoading,
    handleProjectSelect,
    selectedProject,
    handleSelectAllClick,
    isAllSelected,
    selectedDatasets,
    handleDatasetSelectionChange,
    handleImportSelected,
    importDataset,
    getCleanDatasetName,
    fetchDatasetInfo,
  } = props;

  // Local state for stepper
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Select Project', 'Select Datasets', 'Review & Import'];

  // Handlers for stepper navigation
  const handleNext = () => {
    if (activeStep === 1) {
      handleImportSelected();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFinish = () => {
    handleCloseModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="select-stp-data-title"
      aria-describedby="select-stp-data-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '80%',
          maxWidth: 800,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          position: 'relative',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="select-stp-data-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Import STP Data for Registration
        </Typography>

        {/* Stepper Component */}
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Step Content */}
        {activeStep === 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Select a Project
            </Typography>
            {isLoading && <LinearProgress sx={{ mb: 2 }} />}
            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
              <Table size="small">
                <TableBody>
                  {projects.map((projectName, index) => (
                    <TableRow
                      key={projectName}
                      hover
                      onClick={() => handleProjectSelect(projectName)}
                      sx={{
                        cursor: 'pointer',
                        bgcolor:
                          selectedProject === projectName
                            ? 'action.selected'
                            : 'inherit',
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{projectName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button
                variant="contained"
                disabled={!selectedProject}
                onClick={handleNext}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {activeStep === 1 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Select Datasets
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                Please select the datasets you wish to use for registration. You can select multiple datasets at once by clicking the checkboxes.
            </Typography>

            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={
                          selectedDatasets.length > 0 &&
                          selectedDatasets.length < datasets.length
                        }
                        checked={isAllSelected}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell>#</TableCell>
                    <TableCell>Sample Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datasets.map((datasetName, index) => {
                    const cleanName = getCleanDatasetName(datasetName);
                    if (cleanName === datasetName) {
                      return (
                        <TableRow
                          key={datasetName}
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedDatasets.includes(datasetName)}
                              onChange={() =>
                                handleDatasetSelectionChange(datasetName)
                              }
                              inputProps={{
                                'aria-label': `select ${datasetName}`,
                              }}
                            />
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{cleanName}</TableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Button
                variant="contained"
                disabled={selectedDatasets.length === 0}
                onClick={handleNext}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {activeStep === 2 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Review & Import
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                Review your selected datasets. You can import each dataset to perform registration using the Action button.
            </Typography>
            {isLoading && <LinearProgress sx={{ mb: 2 }} />}
            <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
              <Table size="small">
                <TableBody>
                  {importedSamples.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.projectName}</TableCell>
                      <TableCell>{item.sampleName}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => importDataset(item.sampleName)}
                        >
                          <IosShareIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleFinish}
                disabled={importedSamples.length === 0}
              >
                Finish
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default ImportModal;
