import React,  { useState, useEffect, useRef } from 'react';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, List, ListItem, ListItemIcon, Typography, Modal, Button, TextField } from '@mui/material';
import { Checkbox, Table, TableContainer, TableBody, TableHead, TableRow, TableCell, TablePagination, Accordion, AccordionDetails, AccordionSummary, Collapse, Tooltip } from '@mui/material';
import { Slider, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
// Other imports...
import FormControlLabel from '@mui/material/FormControlLabel';
import Popper from '@mui/material/Popper';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio'; // Import for Radio
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import WindowIcon from '@mui/icons-material/Window';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import PreviewIcon from '@mui/icons-material/Preview';
import { OpenSeadragonPrimaryViewer, OpenSeadragonSecondaryViewer } from './OpenSeadragonViewer';
import ImportModal from './ImportModal';
import OpenSeadragon from 'openseadragon';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const drawerWidth = 250; 

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '6vh',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const DataItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



function App() {

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [section, setSection] = useState(1);
  const [selectedChannel, setSelectedChannel] = useState('Channel 1');

  const [availableSections, setAvailableSections] = useState([0])

  const marks = availableSections.map(number => ({
    value: parseInt(number),
    label: `${parseInt(number)}`
  }));

  const [selectedSecondaryChannel, setSecondaryChannel] = useState('Channel 1');

  // To monitor if there are any REST API fetch on wait
  const [isLoading, setIsLoading] = useState(false);
  const [isProgress, setIsProgress] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [selectedUploadModality, setSelectedUploadModality] = React.useState('Select Modality')

  const [selectedTab, setSelectedTab] = React.useState(null); // State to manage selected tab

  // 
  const [lmtabvalue, setLMTabValue] = React.useState(0);
  const handleLMTabChange = (event, newValue) => {
    setLMTabValue(newValue)
  }

  function onChangeUploadModality(modality_value) {
    // Setting the upload modality
    setSelectedUploadModality(modality_value)

    // Extracting the substring
    console.log("Secondary sample list: ", secondarySamplesList)
    
    const mod_sub_string = modality_value
    const filteredModalityAvailable = secondarySamplesList.filter(filteredSecondaryFile => filteredSecondaryFile.includes(mod_sub_string))
    
    console.log("Filtered Transformed:", filteredModalityAvailable);

    if (filteredModalityAvailable.length === 0 ) {
      setUploadDatasetNames(['None'])
    }
    else {
      setUploadDatasetNames(filteredModalityAvailable)
    }
    
  }


  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  

  // Data Selection elements
  const [modalOpen, setModalOpen] = useState(true);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  // Map the drawer items to tabs for viewing in the drawer area
  const drawerItems = [
    { text: 'Portal Home', icon: <WindowIcon /> },
    { text: 'Select STP Data', icon: <PermMediaIcon />, onClick: handleOpenModal },
    // { text: 'Landmark Tools', icon: <EditIcon />, tabContent: <LandmarkToolsTab /> },
    // { text: 'Upload Secondary', icon: <UploadFileTwoToneIcon />, tabContent: <UploadSecondaryTab /> },
    // { text: 'Preview Registration', icon: <PreviewIcon />, tabContent: <PreviewRegistrationTab /> },
    // { text: 'Save Transformation', icon: <SaveAsTwoToneIcon />, tabContent: <SaveTransformationTab /> }
  ];

  const handleItemClick = (item, index) => {
    if (item.onClick) {
      console.log("Onclick")
      item.onClick();
    } else if (item.tabContent) {
      console.log("Tabcontent")
      setSelectedTab(index);
    }
  };

  // Landmark Selection elements
  const anchorEl = useRef(null);

  const [landmarkModalOpen, setLandmarkModalOpen] = useState(false);
  const handleLandmarkOpenModal = () => setLandmarkModalOpen(true);
  const handleLandmarkCloseModal = () => setLandmarkModalOpen(false);
  
  const handleLandmarkClick = (event) => {
    console.log("HanfleLandmarkClick anchor setting")
    if (event.currentTarget instanceof HTMLElement) { 
      console.log(landmarkModalOpen)
      // Toggle anchorEl only when opening the Popper
      if (!landmarkModalOpen) {
        console.log("Setting anchor")
        // setAnchorEl(event.currentTarget);
        anchorEl.current = event.currentTarget
      } 
    }
    setLandmarkModalOpen(!landmarkModalOpen);
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    // setLandmarkModalOpen(!landmarkModalOpen); // Assuming you have a state to control the visibility of the landmark selection tool
  };

  // Registration Preview Elements
  const [anchorPreview, setAnchorPreview] = React.useState(null);

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const handlePreviewOpenModal = () => setPreviewModalOpen(true);
  const handlePreviewCloseModal = () => setPreviewModalOpen(false);

  const handlePreviewClick = (event) => {
    console.log("Handle Preview Click anchor setting")
    if (event.currentTarget instanceof HTMLElement) { // This check ensures that the currentTarget is a valid HTML element
      setAnchorPreview(anchorPreview ? null : event.currentTarget);
    }
    setPreviewModalOpen(!previewModalOpen);
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    // setLandmarkModalOpen(!landmarkModalOpen); // Assuming you have a state to control the visibility of the landmark selection tool
  };

  // Upload full-resolution section elements
  const [anchorUploadSection, setAnchorUploadSection] = React.useState(null);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  
  const handleUploadClick = (event) => {
    console.log("Handle Upload Click anchor setting")
    if (event.currentTarget instanceof HTMLElement) { // This check ensures that the currentTarget is a valid HTML element
      setAnchorUploadSection(anchorUploadSection ? null : event.currentTarget);
    }
    setUploadModalOpen(!uploadModalOpen);
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    // setLandmarkModalOpen(!landmarkModalOpen); // Assuming you have a state to control the visibility of the landmark selection tool
  };

  // const handleUploadFileSelection = async (event) => {
    
  //   const files = event.target.files;
  //   console.log("Selected files: ",files)

  //   // Setting the selectedupload files variable
  //   setSelectedUploadFiles(files)
  //   selectedUploadFilesRef.current = files
  // }

  const handleUploadFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedUploadFiles(files);
    selectedUploadFilesRef.current = files
  };


  // Registration Preview Elements
  const [anchorSavePreview, setAnchorSavePreview] = React.useState(null);

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const handleSaveOpenModal = () => setSaveModalOpen(true);
  const handleSaveCloseModal = () => setSaveModalOpen(false);

  const handleSaveClick = (event) => {
    console.log("Handle Save Click anchor setting")
    if (event.currentTarget instanceof HTMLElement) { // This check ensures that the currentTarget is a valid HTML element
      setAnchorSavePreview(anchorSavePreview ? null : event.currentTarget);
    }
    setSaveModalOpen(!saveModalOpen);
    // setAnchorEl(anchorEl ? null : event.currentTarget);
    // setLandmarkModalOpen(!landmarkModalOpen); // Assuming you have a state to control the visibility of the landmark selection tool
  };

  const [projects, setProjects] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const getCleanDatasetName = (name) => {
    // Regex to detect and remove leading prefixes like 'ABC-01_' or 'XYZ-99_'
    const regex = /^[A-Z-]+(?:-\d{2})?_(.*)$/;
    const match = name.match(regex);
    // console.log(match)
    return match ? match[1] : name; // Returns the part after the prefix if matched, otherwise the original name
  };

  // fetching the projects from flask endpoint
  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true); // Start loading when the fetch operation begins
      try {
        const response = await fetch('https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/projects-list');
        const data = await response.json();
        console.log(data);
        setProjects(data);
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setIsLoading(false); // Stop loading when the fetch operation is complete
      }
    };
  
    fetchProjects();
  }, []);


  const [selectedProject, setSelectedProject] = useState(null);

  const handleProjectSelect = async (projectId) => {
    // Setting the selectedProject const
    setSelectedProject(projectId);
    setSelectedDatasets([]); // Clear selected datasets on project change
    setIsLoading(true); // Start loading
    try {
      const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/datasets-list/${projectId}`);
      const data = await response.json();
      setDatasets(data);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
     
  };

  // Registration Metadata inputs
  const [fixedPixelSizeX, setFixedPixelSizeX] = useState('1.3');
  const [fixedPixelSizeY, setFixedPixelSizeY] = useState('1.3');
  const [movingPixelSizeX, setMovingPixelSizeX] = useState('1.3');
  const [movingPixelSizeY, setMovingPixelSizeY] = useState('1.3');

  const [previewPath, setPreviewPath] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  useEffect(() => {
    console.log("UseEffect called")
    console.log("Preview path: ",previewPath)
    console.log("Preview visible: ", previewVisible)
    if (previewVisible && previewPath) {
      console.log("Setting preview to visible")
      console.log("Preview Path: ",previewPath)
      let newOSDPath = encodeURIComponent(previewPath)
      let newOSDURL = `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-preview-image/${newOSDPath}`
      console.log("OSD URL: ", newOSDURL)
      let newOSDEndPoint = { type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-preview-image/${newOSDPath}` } ;
      setSecondaryOSD(newOSDEndPoint)
      // setSecondaryPath({previewPath})
    }
    if (!previewVisible) {
      // Hide the preview and update the secondaryOSD path
      console.log("setting preview to hidden")
      console.log("Previous selected secondary path: ", selectedSecondaryDatasetPath.secondaryFilePath)
      let prevOSDPath = encodeURIComponent(selectedSecondaryDatasetPath.secondaryFilePath);
      let prevOSDURL = { type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/${prevOSDPath}` } ;
      // Setting the secondary viewer status 
      setSecondaryViewerStatus(false)
      console.log(secondaryViewerReady)
      // Setting the new osd path to update
      setSecondaryOSD(prevOSDURL)
      // Reloading all the secondary landmarks if status is ready
      if (secondaryViewerReady) {
        console.log("Viewer Ready")
        // reloadSecondaryOverlays()
      }
      else {
        console.log("Viewer not ready")
      }
    }
    
  }, [ previewVisible]);

  const togglePreview = () => {
    console.log("toggle preview clicked")
    if (!previewVisible) {
      setUserInstruction("Instruction: Click Hide Preview to go back to the raw image.");
    }
    else {
      setUserInstruction("Instruction: Click Show Preview to view the recent transformed result.");
    }
    setPreviewVisible(!previewVisible)
  }

  const handleInputChange = (event, setter) => {
    const { value } = event.target;
    setter(value);
  };

  const handleSubmit = async () => {
    setIsProgress(true);
    const payload = {
      fixedPixelSizeX,
      fixedPixelSizeY,
      movingPixelSizeX,
      movingPixelSizeY,
      primaryLandmarks,
      secondaryLandmarks,
      selectedPrimaryDatasetPath,
      selectedSecondaryDatasetPath,
    };
    console.log("Payload: ",payload)
    // Assuming your REST API endpoint is 'https://yourapi.com/api/preview'
    try {
      const response = await fetch('https://nuszlkyz6d.execute-api.us-east-1.amazonaws.com/transform-dev/perform-transform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      console.log("File path: ", data.saved_file_path)
      setPreviewPath(data.saved_file_path);
      // Handle success response
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }
    finally {
      setIsProgress(false); // Set loading to false after the API call completes
      setUserInstruction("Instruction: Registration complete. Click Show Preview to visualize.");
    }
  };

  async function generateSmartLandmarks() {
    setIsProgress(true);
  
    try {
      // Fetch the model path
      const model_path = await getLandmarkModel();
  
      // Call both functions and wait for both to complete
      await Promise.all([
        generatePrimarySmartLandmarks(model_path),
        generateSecondarySmartLandmarks(model_path)
      ]);
    } catch (error) {
      console.error('Error in generating landmarks:', error);
      // Handle errors as needed
    } finally {
      setIsProgress(false);
      setUserInstruction("Instruction: Smart Landmark Detection Complete. Hover over landmarks to Edit/Delete.");
    }
  }
  

  const getLandmarkModel = async () => {
    let filePath = selectedPrimaryDatasetPath.sampleFilePath;
    let performSim = true;
    console.log("Dataset Info: ",datasetInfo)
    console.log("Section resolution:", datasetInfo["Section Resolution"])
    let sectionResolution = datasetInfo["Section Resolution"]
    const payload = {
      filePath,
      performSim,
      sectionResolution
    };
    console.log("Payload: ", payload);
    console.log(selectedPrimaryDatasetPath.sampleFilePath);
  
    try {
      const response = await fetch('https://mtmgdbugdj.us-east-1.awsapprunner.com/get-aldm-model', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Success:', data);
  
      // Extract and return the model_path from the response
      let model_path = data.model_path;
      return model_path;
    } catch (error) {
      console.error('Error:', error);
      throw error; 
    }
  };
  

  const generatePrimarySmartLandmarks = async (modelPath) => {
    let filePath = selectedPrimaryDatasetPath.sampleFilePath
    let rotationAngle = 0 // Rotation angle assumed to be zero for STP datasets
    const payload = {
      filePath,
      modelPath,
      rotationAngle
    };
    console.log("Payload: ",payload)
    console.log(selectedPrimaryDatasetPath.sampleFilePath)
    try {
      const response = await fetch('https://mtmgdbugdj.us-east-1.awsapprunner.com/get-aldm-landmarks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      // Extracting the landmarks from the request response
      let primary_ld = data.landmark_dict.Landmarks
      setPrimaryLandmarks(primary_ld)
      createPrimaryOverlays(primary_ld)
      // Handle success response
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }
  };

  const generateSecondarySmartLandmarks = async (modelPath) => {
    let filePath = selectedSecondaryDatasetPath.secondaryFilePath
    let rotationAngle = secondaryRotationRef.current
    console.log("rotation angle: ", rotationAngle)
    const payload = {
      filePath,
      modelPath,
      rotationAngle
    };
    console.log("Payload: ",payload)
    try {
      const response = await fetch('https://mtmgdbugdj.us-east-1.awsapprunner.com/get-aldm-landmarks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      // Extracting the landmarks from the request response
      let secondary_ld = data.landmark_dict.Landmarks
      setSecondaryLandmarks(secondary_ld)
      createSecondaryOverlays(secondary_ld)
      // Handle success response
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
      if (error == '400') {
        console.log('Request body to API must be JSON')
      }
      if (error == '404') {
        console.log('File is not found on Server side. Please upload')
      }
    }
  };


  // Define your icons here
  const leftIcons = [
    { icon: <PermMediaIcon />, name: 'Select STP Data', onClick: handleOpenModal },
    { icon: <EditIcon />, name: 'Landmark Tool', onClick: handleLandmarkClick },
    // { icon: <TuneIcon />, name: 'Channel Settings' },
    { icon: <InfoIcon />, name: 'Metadata Info' },
  ];
  
  const [saveLandmarks, setSaveLandmarks] = useState(false);
  const [datasetOption, setDatasetOption] = useState('existing');
  const [uploadDatasetOption, setUploadDatasetOption] = useState('existing');
  const [selectedUploadName, setSelectedUploadName] = useState('');
  const [selectedUploadFiles, setSelectedUploadFiles] = useState([]);
  const selectedUploadFilesRef = useRef(selectedUploadFiles);
  const [uploadDatasetNames, setUploadDatasetNames] = useState([]);
  const [transformedDatasetNames, setTransformedDatasetNames] = useState([]);
  const [selectedTransformedName, setSelectedTransformedName] = useState('')

  useEffect(() => {
    console.log("Upload files Updated: ", selectedUploadFiles);
    selectedUploadFilesRef.current = selectedUploadFiles;
  }, [selectedUploadFiles]);

  const handleUploadNameChange  = (event) => {
    let sample_name = event.target.value
    setSelectedUploadName(event.target.value);
    console.log("Selected: ",event.target.value)
  }

  const handleTransformedNameChange  = (event) => {
    let sample_name = event.target.value
    setSelectedTransformedName(event.target.value);
    console.log("Selected: ",event.target.value)
  }

  const appendTransformedDatasetName = (newName) => {
    // Appending the new tranformed name to existing list of transformed names
    setTransformedDatasetNames(prevNames => [...prevNames, newName])
    // Appending the new name to the secondary sample list to allow selection 
    setSecondarySamplesList(prevNames => [...prevNames, newName])
  }

  // Handling the import landmarks select element in the LAndmark Options
  const handleImportLandmarks = async(event) => {
    let selected_sample = event.target.value
    console.log("Selected: ", selected_sample)

    const payload = {
      selectedPrimaryDatasetPath,
      selected_sample,
      section
    }

    console.log("Payload: ",payload)
    // Assuming your REST API endpoint is 'https://yourapi.com/api/preview'
    try {
      const response = await fetch('https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/import-landmarks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Success:', data);
      // Setting the primaryLandmarks
      let primary_ld = data.landmarks.primaryLandmarks
      let secondary_ld = data.landmarks.secondaryLandmarks
      setPrimaryLandmarks(primary_ld)
      setSecondaryLandmarks(secondary_ld)
      createPrimaryOverlays(primary_ld)
      createSecondaryOverlays(secondary_ld)
      // Handle success response
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }


  }
  const [newDatasetName, setNewDatasetName] = useState('');


  const uploadToCloud = async () => {
    setIsProgress(true);
  
    let outputUploadName;
    const filePrefixes = []; // Array to store file-prefixes for all uploaded files
    // Adding the Secondary modality prefix to the Selected Sample
    outputUploadName = selectedUploadModality + '_' + selectedSample
    setSelectedUploadName(outputUploadName)
    // Check which radio button is clicked and handle accordingly
    // if (uploadDatasetOption === 'existing') {
    //   if (!selectedUploadName) {
    //     alert('Existing dataset not selected');
    //   } else {
    //     console.log('Selected dataset:', selectedUploadName);
    //     outputUploadName = selectedUploadName;
    //   }
    // } else if (uploadDatasetOption === 'new') {
    //   console.log('Creating new dataset');
    //   console.log("Selected Modality: ", selectedUploadModality);
    //   console.log("Selected primary: ", selectedSample);
    //   if (uploadDatasetNames[0] === 'None' || uploadDatasetNames.length === 0) {
    //     outputUploadName = selectedUploadModality + '_' + selectedSample;
    //     setSelectedUploadName(outputUploadName);
    //     console.log(outputUploadName);
    //     console.log("New name: ", selectedUploadName);
    //   } else {
    //     const uploadNumbers = uploadDatasetNames
    //       .filter(name => name.startsWith(selectedUploadModality + '-'))
    //       .map(name => {
    //         const splitName = name.split('_')[0];
    //         const numberPart = splitName.split('-')[1];
    //         return parseInt(numberPart, 10);
    //       });
    //     const maxUploadNumber = uploadNumbers.length > 0 ? Math.max(...uploadNumbers) : 0;
    //     outputUploadName = selectedUploadModality + `-` + `${String(maxUploadNumber + 1).padStart(2, '0')}_${selectedSample}`;
    //     setSelectedUploadName(outputUploadName);
    //     console.log("New name: ", outputUploadName);
    //   }
    // }

    console.log('Selected upload files: ', selectedUploadFiles, selectedUploadFilesRef.current);
    console.log("Selected primary: ", selectedPrimaryDatasetPath, selectedPrimaryDatasetPath.sampleFilePath);

    try {
      let chan_ind = 1;
      for (let file of selectedUploadFiles) {
        const presignedUrlResponse = await fetch('https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/generate-presigned-url', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            outputUploadName: outputUploadName,
            modality: selectedUploadModality,
            selectedPrimaryDatasetPath: selectedPrimaryDatasetPath.sampleFilePath,
            section: section,
            file_type: file.type,
            chan_ind: chan_ind
          }),
        });
        chan_ind += 1;
  
        const { url, filepath } = await presignedUrlResponse.json();
        filePrefixes.push(filepath); // Collect each file-prefix

        await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          body: file,
        });
      }
  
      console.log('Files uploaded successfully');
      for (let filePrefix of filePrefixes) {
        console.log("Downsampling: ", filePrefix)
        // Call the additional API after the upload is successful
        const downsampleApiResponse = await fetch('http://localhost:4000/save-downsampled', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            highQualityImgKey: filePrefix,  // Pass the outputUploadName here
          }),
        });

        const responseText = await downsampleApiResponse.text();
        console.log('Downsample API response text:', responseText);

        if (!downsampleApiResponse.ok) {
          throw new Error('Downsample API call failed: ' + responseText);
        }

        const responseData = JSON.parse(responseText);
        console.log('Downsample API response JSON:', responseData);
      }

      console.log("Uploading secondary sample name: ", outputUploadName)
      saveSecondaryMetadata(outputUploadName, chan_ind)
  
    } catch (error) {
      console.error('File upload or API call failed:', error);
    } finally {
      setIsProgress(false);
    }
  };

  const saveSecondaryMetadata = async (secondarySampleName, channels) => {
    // Save the metadata json file for secondary datasets
    const saveMetadataResponse = await fetch('http://localhost:5000/save-secondary-metadata', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        PrimaryDataName: selectedSample,  // Pass the outputUploadName here
        SecondaryDataName: secondarySampleName,
        SectionNum:section,
        projectId: selectedProject,
        Channels: channels
      }),
    });

    const responseText = await saveMetadataResponse.text();
    console.log('Downsample API response text:', responseText);

    if (!saveMetadataResponse.ok) {
      throw new Error('Downsample API call failed: ' + responseText);
    }

    const responseData = JSON.parse(responseText);
    console.log('Downsample API response JSON:', responseData);
    
  }

  const saveTransformedDataset = async () => {

    setIsProgress(true);

    let outputTransformedName 
    
    // Saving the output transformed dataset name with the prefix REG
    console.log("Secondary dataset name: ", selectedSecondarysample)
    outputTransformedName = 'REG_'+ selectedSecondarysample
    console.log("Reg secondary name: ", outputTransformedName)
    
    // Setting the outputtransformed name
    setSelectedTransformedName(outputTransformedName)

    console.log("selected primary path: ", selectedPrimaryDatasetPath)
    // Printing to console as required
    console.log("Primary Landmarks:", primaryLandmarks);
    console.log("Secondary Landmarks:", secondaryLandmarks);

    // Save modified landmarks as json file to S3
    // Prepare data for the API
    const data = {
      primaryLandmarks,
      secondaryLandmarks,
      selectedPrimaryDatasetPath,
      outputTransformedName,
      section
    };

    // API endpoint
    const apiUrl = 'https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/save-landmarks';

    try {
      // Send data to the backend API using fetch
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('API Response:', responseData);
      
      if (datasetOption === 'new') {
        console.log("It is a new dataset. Appending..")
        // Appending the new transform data to add it to dynamically display in frontend
        appendTransformedDatasetName(outputTransformedName)
      }
      
    } catch (error) {
      console.error('API Call Failed:', error);
    }
      
    // Perform API call to save the transformed secondary datasets to cloud S3

    const transformURL = 'https://nuszlkyz6d.execute-api.us-east-1.amazonaws.com/transform-dev/save-transform';
    // Extracting the required parameters
    let primaryFilePath = selectedPrimaryDatasetPath.sampleFilePath
    let secondaryFilePath = selectedSecondaryDatasetPath.secondaryFilePath
    let channels = secondaryDatasetInfo["Channels"]
    // Creating the payload for the api call
    const payload = {
      fixedPixelSizeX,
      fixedPixelSizeY,
      movingPixelSizeX,
      movingPixelSizeY,
      primaryLandmarks,
      secondaryLandmarks,
      primaryFilePath,
      secondaryFilePath,
      outputTransformedName,
      channels
    };

    try {
      // Send data to the backend API using fetch
      const response = await fetch(transformURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('API Response:', responseData);
    } catch (error) {
      console.error('API Call Failed:', error);
    }
    finally {
      setIsProgress(false); // Set loading to false after the API call completes
    }
    
  };

  const saveTransformation = async () => {

    setIsProgress(true);

    let outputTransformedName 

    // Check which radio button is clicked and handle accordingly
    if (datasetOption === 'existing') {
      // Assume the select value is properly managed and retrieved from the state
      if (!selectedTransformedName) {
        alert('Existing dataset not selected');
      } else {
        console.log('Selected dataset:', selectedTransformedName);
        // Additional actions for the existing dataset can be added here
        outputTransformedName = selectedTransformedName
      }
    } else if (datasetOption === 'new') {
      // Handle logic for new dataset creation
      console.log('Creating new dataset');
      console.log("Secondary Sample List: ",secondarySamplesList)
      console.log("filtered Transform available: ", transformedDatasetNames)
      console.log("Selected primary smaple: ", selectedSample)
      console.log("selected project: ", selectedProject)
      if (transformedDatasetNames[0] === 'None' || transformedDatasetNames.length ===0 ) {
        outputTransformedName = 'REG-01_'+selectedSample
        setSelectedTransformedName(outputTransformedName)
        console.log(outputTransformedName)
        console.log("New name: ", selectedTransformedName)
      }
      else {
        console.log("filtered Transform available: ", transformedDatasetNames)
        const regNumbers = transformedDatasetNames
        .filter(name => name.startsWith('REG-'))
        .map(name => {
          // Assuming the name format is 'REG-XX_something'
          const splitName = name.split('_')[0]; // This splits 'REG-01_example' to ['REG-01', 'example']
          const numberPart = splitName.split('-')[1]; // This gets '01' from 'REG-01'
          return parseInt(numberPart, 10); // Convert string number to integer
        });

      // Determine the maximum value found or default to 0 if no valid format is found
      const maxRegNumber = regNumbers.length > 0 ? Math.max(...regNumbers) : 0;

      // Increment to create a new unique 'REG-XX' name
      outputTransformedName = `REG-${String(maxRegNumber + 1).padStart(2, '0')}_${selectedSample}`;
      setSelectedTransformedName(outputTransformedName);
      console.log("New name: ", outputTransformedName);
      }
    }
    console.log("selected primary path: ", selectedPrimaryDatasetPath)
    // Check if Save Landmarks is checked
    if (saveLandmarks) {
      // Printing to console as required
      console.log("Primary Landmarks:", primaryLandmarks);
      console.log("Secondary Landmarks:", secondaryLandmarks);
  
      // Prepare data for the API
      const data = {
        primaryLandmarks,
        secondaryLandmarks,
        selectedPrimaryDatasetPath,
        outputTransformedName,
        section
      };
  
      // API endpoint
      const apiUrl = 'https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/save-landmarks';
  
      try {
        // Send data to the backend API using fetch
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        console.log('API Response:', responseData);
        
        if (datasetOption === 'new') {
          console.log("It is a new dataset. Appending..")
          // Appending the new transform data to add it to dynamically display in frontend
          appendTransformedDatasetName(outputTransformedName)
        }
        

      } catch (error) {
        console.error('API Call Failed:', error);
      }
      
    }

    const transformURL = 'https://nuszlkyz6d.execute-api.us-east-1.amazonaws.com/transform-dev/save-transform';
    // Extracting the required parameters
    let primaryFilePath = selectedPrimaryDatasetPath.sampleFilePath
    let secondaryFilePath = selectedSecondaryDatasetPath.secondaryFilePath

    // Creating the payload for the api call
    const payload = {
      fixedPixelSizeX,
      fixedPixelSizeY,
      movingPixelSizeX,
      movingPixelSizeY,
      primaryLandmarks,
      secondaryLandmarks,
      primaryFilePath,
      secondaryFilePath,
      outputTransformedName,
      secondaryDatasetInfo
    };

    try {
      // Send data to the backend API using fetch
      const response = await fetch(transformURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('API Response:', responseData);
    } catch (error) {
      console.error('API Call Failed:', error);
    }
    finally {
      setIsProgress(false); // Set loading to false after the API call completes
    }
    
  };



  const speedIcons = [
    { icon: <EditIcon />, name: 'Landmark Tool', onClick: handleLandmarkClick },
    { icon: <PreviewIcon />, name: 'Preview Registration', onClick: handlePreviewClick },
    { icon: <SaveIcon />, name: 'Save Transformation', onClick: handleSaveClick },
    { icon: <CloudUploadIcon />, name: 'Upload Secondary', onClick:handleUploadClick }
  ];

  const [selectedDatasets, setSelectedDatasets] = useState([]);

  // Toggle individual dataset selection
  const handleDatasetSelectionChange = (datasetName) => {
    setSelectedDatasets((prevSelected) =>
      prevSelected.includes(datasetName)
        ? prevSelected.filter((name) => name !== datasetName)
        : [...prevSelected, datasetName]
    );
  };

  // Check if all datasets are selected
  // const isAllSelected = datasets.length > 0 && selectedDatasets.length === datasets.length;
  const isAllSelected = datasets.length > 0 && selectedDatasets.length === datasets.filter(dataset => getCleanDatasetName(dataset) === dataset).length;

  // Toggle selection of all datasets
  const handleSelectAllClick = () => {
    if (isAllSelected) {
      setSelectedDatasets([]);
    } else {
      // Only select datasets where the clean name matches the dataset name
      const cleanDatasets = datasets.filter(
        (dataset) => getCleanDatasetName(dataset) === dataset
      );
      setSelectedDatasets(cleanDatasets);
      }
  };

  // Function for importing the selected samples form the grid table
  const [importedSamples, setImportedSamples] = useState([]);

  const [registrationData, setRegistrationData] = useState([]);

  const handleImportSelected = () => {
    // Assuming `selectedProject` holds the currently selected project's name
    const samplesToImport = datasets.filter(dataset => selectedDatasets.includes(dataset));
    setImportedSamples(samplesToImport.map(sample => ({ projectName: selectedProject, sampleName: sample })));
    const newData = selectedDatasets.map(datasetName => ({
      projectName: selectedProject,
      sampleName: datasetName
    }));
    setRegistrationData(newData);
  };

  const [selectedSample, setSelectedSample] = useState([]);

  // Update list of available secondary datasets in Cloud
  const [secondarySamplesList, setSecondarySamplesList] = useState([]);

  const [selectedSecondarysample, setSecondarySample] = useState([]);

  const [selectedSampleFilesList, setSampleFilesList] = useState([]);
  const [selectedSampleFullresFilesList, setFullresSampleFilesList] = useState([]);

  const [selectedSecondaryFilesList, setSecondaryFilesList] = useState([]);
  const [selectedSecondaryFullresFilesList, setSecondaryFullresFilesList] = useState([]);

  const importDataset = (sampleName) => {
    // Registration logic for the given sampleName
    setSelectedSample(sampleName)
    console.log(`Performing registration for ${selectedSample}`);
    console.log(`Performing registration for ${selectedProject}`);
    // Fetching downsampled  file list for primary sample
    const fetchFilesList = async (sampleName) => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-list/${selectedProject}/${sampleName}`);
        const json = await response.json();
        console.log(json)
        // Check if the response is an array
        if (Array.isArray(json)) {
          // If it's already an array, directly set it
          console.log("It is an array")
          setSampleFilesList(json);
        } else if (typeof json === 'string') {
          // If it's a string, convert it to an array of strings
          console.log("It is not an array")
          setSampleFilesList([json]);
        } else {
          // If it's neither an array nor a string, handle accordingly
          console.error("Unexpected response format:", json);
        }
      } catch (error){
        console.error("Failed to fetch dataset info:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of the outcome
      }
      // Closing the Data selection
      handleCloseModal()
    }

    // Fetching full res file list for primary sample
    const fetchFullresFilesList = async (sampleName) => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-fullres-list/${selectedProject}/${sampleName}`);
        const json = await response.json();
        console.log(json)
        // Check if the response is an array
        if (Array.isArray(json)) {
          // If it's already an array, directly set it
          console.log("It is an array")
          setFullresSampleFilesList(json);
        } else if (typeof json === 'string') {
          // If it's a string, convert it to an array of strings
          console.log("It is not an array")
          setFullresSampleFilesList([json]);
        } else {
          // If it's neither an array nor a string, handle accordingly
          console.error("Unexpected response format:", json);
        }
      } catch (error){
        console.error("Failed to fetch dataset info:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of the outcome
      }
    }

    // Code to filter through the available dataset list and extract all secondary datasets
    const filterSecondaryFilesList = async (sampleName) => {
      setIsLoading(true);
      try {
        // Extracting all the samples that contains the substring "_<dataset_name>"
        const sub_string = `_${sampleName}`; // Construct the substring to match
        const filteredSecondaryFiles = datasets.filter(dataset => dataset.includes(sub_string));
        console.log("Filtered Secondary:", filteredSecondaryFiles);
    
        // Updating the secondarySampleList variable
        setSecondarySamplesList(filteredSecondaryFiles);

        // Extracting all the transformed samples from the list of available samples
        const tr_sub_string = `REG`
        const filteredTransformedAvailable = filteredSecondaryFiles.filter(filteredSecondaryFile => filteredSecondaryFile.includes(tr_sub_string))
        console.log("Filtered Transformed:", filteredTransformedAvailable);
        if (filteredTransformedAvailable.length === 0 ) {
          setTransformedDatasetNames(['None'])
        }
        else {
          setTransformedDatasetNames(filteredTransformedAvailable)
        }
        
    
        // Since state updates are asynchronous, use filteredSecondaryFiles directly for immediate operations
        console.log("Secondary sample list (immediate): ", filteredSecondaryFiles);
        if(filteredSecondaryFiles.length > 0) {
          console.log("Secondary (immediate): ", filteredSecondaryFiles[0]);
          setSecondarySample(filteredSecondaryFiles[0]);
        } else {
          console.log("No secondary samples found");
        }
    
        // If you need to do something once the state has updated, consider using useEffect hook elsewhere in your component
    
      } catch (error) {
        console.error("Failed to fetch dataset info:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of the outcome
      }
    } 

    // Filter and Extract all available secondary samples for selected sample
    filterSecondaryFilesList(sampleName)

    // Fetching list of filenames for the select sample
    fetchFilesList(sampleName)

    // Fetching metadata information for the select sample
    fetchDatasetInfo(sampleName)
    
    // Fetching list of filenames for the select sample
    fetchFullresFilesList(sampleName)


    
  };

  const updateSecondarySampleFilesList = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-list/${selectedProject}/${selectedSecondarysample}`);
      const json = await response.json();
      console.log(json)
      // Check if the response is an array
      if (Array.isArray(json)) {
        // If it's already an array, directly set it
        console.log("It is an array")
        setSecondaryFilesList(json);
      } else if (typeof json === 'string') {
        // If it's a string, convert it to an array of strings
        console.log("It is not an array")
        setSecondaryFilesList([json]);
      } else {
        // If it's neither an array nor a string, handle accordingly
        console.error("Unexpected response format:", json);
      }
    } catch (error){
      console.error("Failed to fetch dataset info:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  }

  const updateSecondaryFullresSampleFilesList = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-fullres-list/${selectedProject}/${selectedSecondarysample}`);
      const json = await response.json();
      console.log(json)
      // Check if the response is an array
      if (Array.isArray(json)) {
        // If it's already an array, directly set it
        console.log("It is an array")
        setSecondaryFullresFilesList(json);
      } else if (typeof json === 'string') {
        // If it's a string, convert it to an array of strings
        console.log("It is not an array")
        setSecondaryFullresFilesList([json]);
      } else {
        // If it's neither an array nor a string, handle accordingly
        console.error("Unexpected response format:", json);
      }
    } catch (error){
      console.error("Failed to fetch dataset info:", error);
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  }

  // Defining the state to store the clicked dataset info for metadata retrieval
  const [datasetInfo, setDatasetInfo] = useState();

  // Function to fetch dataset information
  const fetchDatasetInfo = async (datasetName) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-metadata/${selectedProject}/${datasetName}`);
      const json = await response.json();
      const getValue = (obj, path) => obj && path in obj ? obj[path] : "No data";

      const data = {
        "Dataset Name": datasetName,
        "Sample ID": getValue(json.mosaic_file, "Sample ID"),
        "Scanner ID": getValue(json.mosaic_file, "ScannerID"),
        "Acquisition Date": getValue(json.mosaic_file, "acqDate"),
        "Description": getValue(json.mosaic_file, "Description"),
        "X Pixel Micron": getValue(json.stage_stitch, "x_pixel_micron"),
        "Y Pixel Micron": getValue(json.stage_stitch, "y_pixel_micron"),
        "Layers": getValue(json.mosaic_file, "layers"),
        "Sections": getValue(json.mosaic_file, "sections"),
        "Section Resolution": getValue(json.mosaic_file, "sectionres"),
        "Channels": getValue(json.mosaic_file, "channels"),
        "Comments": getValue(json.mosaic_file, "comments")
      };
      // Assuming the API returns data that can directly be set to datasetInfo
      // You might need to adjust the data format depending on your API response
      setDatasetInfo(data); // Wrapping in an array for consistent table rendering, adjust as necessary
    } catch (error) {
      console.error("Failed to fetch dataset info:", error);
      setDatasetInfo({
        "Dataset Name": "No data",
        "Sample ID": "No data",
        "Scanner ID": "No data",
        "Acquisition Date": "No data",
        "Description": "No data",
        "X Pixel Micron": "No data",
        "Y Pixel Micron": "No data",
        "Layers": "No data",
        "Sections": "No data",
        "Section Resolution": "No data",
        "Channels": "No data",
        "Comments": "No data"
      });
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  // Define channel options based on datasetInfo
  let channelOptions = [];
  if (datasetInfo && datasetInfo.Channels) {
    channelOptions = Array.from({length: datasetInfo.Channels}, (_, i) => `Channel ${i + 1}`);
  }

  // Defining the state to store the clicked dataset info for metadata retrieval
  const [secondaryDatasetInfo, setSecondaryDatasetInfo] = useState();

  // Function to fetch dataset information
  const fetchSecondaryDatasetInfo = async () => {
    setIsLoading(true);
    try {
      console.log("Fetching secondary metadata of: ", selectedSecondarysample)
      const response = await fetch(`https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-metadata-secondary/${selectedProject}/${selectedSecondarysample}`);
      const json = await response.json();
      const getValue = (obj, path) => obj && path in obj ? obj[path] : "No data";
      console.log("Response json: ",json)
      const data = {
        "Dataset Name": selectedSecondarysample,
        "Sample ID": getValue(json.mosaic_file, "Sample ID"),
        "Acquisition Date": getValue(json.mosaic_file, "acqDate"),
        "Description": getValue(json.mosaic_file, "Description"),
        "X Pixel Micron": getValue(json.stage_stitch, "x_pixel_micron"),
        "Y Pixel Micron": getValue(json.stage_stitch, "y_pixel_micron"),
        "Channels": getValue(json.mosaic_file, "channels"),
        "Comments": getValue(json.mosaic_file, "comments")
      };
      console.log("Response data: ",data)
      console.log("Sections: ", json.section_numbers)
      // Assuming the API returns data that can directly be set to datasetInfo
      // You might need to adjust the data format depending on your API response
      setSecondaryDatasetInfo(data); // Wrapping in an array for consistent table rendering, adjust as necessary
      // Setting the available section number information
      setAvailableSections(json.section_numbers)
    } catch (error) {
      console.error("Failed to fetch dataset info:", error);
      setSecondaryDatasetInfo({
        "Dataset Name": "No data",
        "Sample ID": "No data",
        "Acquisition Date": "No data",
        "Description": "No data",
        "X Pixel Micron": "No data",
        "Y Pixel Micron": "No data",
        "Channels": "No data",
        "Comments": "No data"
      });
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  // Define channel options based on datasetInfo
  let secondarychannelOptions = [];
  if (secondaryDatasetInfo && secondaryDatasetInfo.Channels) {
    secondarychannelOptions = Array.from({length: secondaryDatasetInfo.Channels}, (_, i) => `Channel ${i + 1}`);
  }

  const [maxSections, setMaxSections] = useState(1);

  useEffect(() => {
    if (datasetInfo && datasetInfo.Sections) {
      setMaxSections(datasetInfo.Sections);
    }
  }, datasetInfo);

  const updatePrimaryFilePath = () => {
    if (selectedSampleFilesList.length === 0) return;
    console.log("Section:",section)
    console.log("selected Channel",selectedChannel)
    const channelSuffix = selectedChannel.slice(-1); // Extract the last character, which should be the channel number
    const channelSubstring = `ch0${channelSuffix}`; // Construct the substring to match

    const filteredFiles = selectedSampleFilesList.filter(filePath => filePath.includes(channelSubstring));
    // Assuming the section number corresponds directly to the index in the filtered list
    const index = section - 1; // Convert section number to zero-based index
    const sampleFilePath = filteredFiles.length > index ? filteredFiles[index] : null;
    
    // Use sampleFilePath here to trigger fetch operations or update state as needed
    console.log('Selected Sample File Path:', sampleFilePath);
    
    // For example, you might set state here to cause a re-render with the new path
    // This is just an example, replace it with your actual fetch or state update logic
    // setState({ selectedFilePath: sampleFilePath });
    // Example file paths and tile sources
    let filePath1 = encodeURIComponent(sampleFilePath);
    let flaskEndpoint1 = { type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/${filePath1}` } ;
    setPrimaryViewerStatus(false)
    setPrimaryOSD(flaskEndpoint1)
    setPrimaryPath({sampleFilePath})
    // primaryImageTileSource = { type: 'image', url: flaskEndpoint1 };
  };

  const updateSecondaryFilePath = () => {
    if (selectedSecondaryFilesList.length === 0) return;
    console.log("Section:",section)
    console.log("selected Channel",selectedSecondaryChannel)
    const channelSuffix = selectedSecondaryChannel.slice(-1); // Extract the last character, which should be the channel number
    const channelSubstring = `ch0${channelSuffix}`; // Construct the substring to match

    // Format the section number to match the required substring format (e.g., S000009 for section 9)
    const sectionSubstring = `S${section.toString().padStart(6, '0')}`;
    console.log("substring: ",sectionSubstring)
    const filteredFiles = selectedSecondaryFilesList.filter(filePath => filePath.includes(channelSubstring) && filePath.includes(sectionSubstring));
    
    // Since we're directly filtering by the specific section, we don't use the index for secondary files
    const secondaryFilePath = filteredFiles.length > 0 ? filteredFiles[0] : null;

    // Use sampleFilePath here to trigger fetch operations or update state as needed
    console.log('Selected Sample File Path:', secondaryFilePath);
    
    // For example, you might set state here to cause a re-render with the new path
    // This is just an example, replace it with your actual fetch or state update logic
    // setState({ selectedFilePath: sampleFilePath });
    // Example file paths and tile sources
    let filePath2 = encodeURIComponent(secondaryFilePath);
    let flaskEndpoint2 = { type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/${filePath2}` } ;
    // Setting the secondary viewer status 
    setSecondaryViewerStatus(false)
    setSecondaryOSD(flaskEndpoint2)
    setSecondaryPath({secondaryFilePath})
    // primaryImageTileSource = { type: 'image', url: flaskEndpoint1 };
  };

  const updateAllFilePath = () => {
    if (selectedSampleFilesList.length === 0) return;
    console.log("Section:",section)
    console.log("selected Channel",selectedChannel)
    const channelSuffix = selectedChannel.slice(-1); // Extract the last character, which should be the channel number
    const channelSubstring = `ch0${channelSuffix}`; // Construct the substring to match

    const filteredFiles = selectedSampleFilesList.filter(filePath => filePath.includes(channelSubstring));
    // Assuming the section number corresponds directly to the index in the filtered list
    const index = section - 1; // Convert section number to zero-based index
    const sampleFilePath = filteredFiles.length > index ? filteredFiles[index] : null;

    const secondarychannelSuffix = selectedSecondaryChannel.slice(-1); // Extract the last character, which should be the channel number
    const secondarychannelSubstring = `ch0${secondarychannelSuffix}`; // Construct the substring to match
    console.log("Second substring: ",secondarychannelSubstring)
    // Format the section number to match the required substring format (e.g., S000009 for section 9)
    const sectionSubstring = `S${section.toString().padStart(6, '0')}`;
    console.log("Section substring: ",sectionSubstring)
    console.log("Secondary files: ", selectedSecondaryFilesList)
    const filteredSecondaryFiles = selectedSecondaryFilesList.filter(filePath => filePath.includes(secondarychannelSubstring) && filePath.includes(sectionSubstring));
    console.log("Secondary filtered: ",filteredSecondaryFiles)
    // Since we're directly filtering by the specific section, we don't use the index for secondary files
    const secondaryFilePath = filteredSecondaryFiles.length > 0 ? filteredSecondaryFiles[0] : 'None';

    // Use sampleFilePath here to trigger fetch operations or update state as needed
    console.log('Selected Sample File Path:', sampleFilePath);
    console.log('Selected Sample File Path:', secondaryFilePath);
    
    // Example file paths and tile sources
    let filePath1 = encodeURIComponent(sampleFilePath);
    let flaskEndpoint1 = { type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/${filePath1}` } ;
    setPrimaryViewerStatus(false)
    setPrimaryOSD(flaskEndpoint1)
    setPrimaryPath({sampleFilePath})

    let filePath2 = encodeURIComponent(secondaryFilePath);
    let flaskEndpoint2 = { type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/${filePath2}` } ;
    // Setting the secondary viewer status 
    setSecondaryViewerStatus(false)
    setSecondaryOSD(flaskEndpoint2)
    setSecondaryPath({secondaryFilePath})
  };

  useEffect(() => {
    updatePrimaryFilePath();
  }, [selectedChannel]); // Add selectedSampleFilesList to ensure the function is called when the list updates
  
  useEffect(() => {
    updateAllFilePath();
    setPrimaryLandmarks([]);
    setSecondaryLandmarks([]);
    createPrimaryOverlays([]);
    createSecondaryOverlays([]);
  }, [section, selectedSampleFilesList]); // Add selectedSampleFilesList to ensure the function is called when the list updates
  
  useEffect(() => {
    updateSecondaryFilePath();
  }, [selectedSecondaryChannel, selectedSecondaryFilesList]); // Add selectedSampleFilesList to ensure the function is called when the list updates
  
  useEffect(() => {
    // This will check if selectedSecondarysample has been updated and is not empty
    if (selectedSecondarysample.length > 0) {
      console.log(`Selected secondary sample has been set:`, selectedSecondarysample);
      // Call the update functions here since selectedSecondarysample has been updated
      updateSecondarySampleFilesList();
      updateSecondaryFullresSampleFilesList();
      fetchSecondaryDatasetInfo();
    }
  }, [selectedSecondarysample]);

  const [primaryImageTileSource, setPrimaryOSD] = useState({ type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/` });
  const [secondaryImageTileSource, setSecondaryOSD] = useState({ type: 'image', url: `https://j33k1nnmq1.execute-api.us-east-1.amazonaws.com/reg-dev/get-downsampled-image/` });

  const [primaryViewerReady, setPrimaryViewerStatus] = useState(false);
  const [secondaryViewerReady, setSecondaryViewerStatus] = useState(false);

  const handlePrimaryViewerStatus = (isReady) => {
    setPrimaryViewerStatus(isReady)
  }
  
  const handleSecondaryViewerStatus = (isReady) => {
    setSecondaryViewerStatus(isReady)
  }

  useEffect(() => {
    if (secondaryViewerReady && secondaryLandmarks.length > 0) {
      console.log("Secondary status ready adding overlays")
      reloadSecondaryOverlays();
    }
    else {
      console.log("Secondary status not ready")
    }
  }, [secondaryViewerReady]);

  useEffect(() => {
    if (primaryViewerReady && primaryLandmarks.length > 0) {
      console.log("Primary status ready adding overlays")
      reloadPrimaryOverlays();
    }
    else {
      console.log("Primary status not ready")
    }
  }, [primaryViewerReady]);

  
  const [selectedPrimaryDatasetPath, setPrimaryPath] = useState({ });
  const [selectedSecondaryDatasetPath, setSecondaryPath] = useState({ });

  // Landmark states
  const [primaryLandmarks, setPrimaryLandmarks] = useState([]);
  const [secondaryLandmarks, setSecondaryLandmarks] = useState([]); 
  const [addingLandmark, setAddingLandmark] = useState(false);
  const [landmarkMode, setLandmarkMode] = useState(false);

  const addingLandmarkRef = useRef(false);
  const primaryLandmarksRef = useRef(primaryLandmarks);
  const secondaryLandmarksRef = useRef(secondaryLandmarks);


  // State variables for monitoring edit mode
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [selectedLandmarkForEdit, setSelectedLandmarkForEdit] = useState(null);
  const [selectedOverlayForEdit, setSelectedOverlayForEdit] = useState(null);
  const isEditModeEnabledRef = useRef(false)
  const selectedLandmarkForEditRef = useRef(null)
  const selectedOverlayForEditRef = useRef(null)

  const [selectedPrimaryLandmarkForEdit, setSelectedPrimaryLandmarkForEdit] = useState(null);
  const [selectedPrimaryOverlayForEdit, setSelectedPrimaryOverlayForEdit] = useState(null);
  const selectedPrimaryLandmarkForEditRef = useRef(null)
  const selectedPrimaryOverlayForEditRef = useRef(null)

  const [selectedSecondaryLandmarkForEdit, setSelectedSecondaryLandmarkForEdit] = useState(null);
  const [selectedSecondaryOverlayForEdit, setSelectedSecondaryOverlayForEdit] = useState(null);
  const selectedSecondaryLandmarkForEditRef = useRef(null)
  const selectedSecondaryOverlayForEditRef = useRef(null)

  const [expandedRowId, setExpandedRowId] = useState(null);
  const [highlightedLandmarkId, setHighlightedLandmarkId] = useState(null)

  const toggleEditingMode = () => {
    if (addingLandmarkRef.current) {
      alert("Adding Landmark Mode is enabled. Disable before editing.")
      return
    }
    if(isEditModeEnabledRef.current){
      isEditModeEnabledRef.current = false;
    }
    else {
      isEditModeEnabledRef.current = true
      // Updating the user instruction to edit the landmarks
      setUserInstruction("Instruction: Please select the required landmark to edit location.");
    }
    
    console.log("Toggling edit mode:", isEditModeEnabledRef.current);

    // Optional: if you want the component to re-render when toggling the mode, set some state here
    setIsEditModeEnabled(isEditModeEnabledRef.current)
  }

  // Landmark Table to add entries in the Landmark Table for each added landmark.
  const LandmarkTable = ({ primaryLandmarks, secondaryLandmarks, expandedRowId, setExpandedRowId, highlightedLandmarkId, setHighlightedLandmarkId }) => {
    const [hoveredLandmark, setHoveredLandmark] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const formatCoordinate = (coordinate) => coordinate.toFixed(2);

    const mergedLandmarks = primaryLandmarks.map((primaryLandmark) => {
        const secondaryLandmark = secondaryLandmarks.find((landmark) => landmark.id === primaryLandmark.id);
        return {
            id: primaryLandmark.id,
            prim_x: formatCoordinate(primaryLandmark.x),
            prim_y: formatCoordinate(primaryLandmark.y),
            sec_x: secondaryLandmark ? formatCoordinate(secondaryLandmark.x) : '-',
            sec_y: secondaryLandmark ? formatCoordinate(secondaryLandmark.y) : '-'
        };
    });

    useEffect(() => {
      if (highlightedLandmarkId !== null) {
        const index = mergedLandmarks.findIndex((landmark) => landmark.id === highlightedLandmarkId);
        const newPage = Math.floor(index / rowsPerPage);
        console.log('Index: ', index)
        console.log("Page: ",newPage)
        setPage(newPage);
        setExpandedRowId(highlightedLandmarkId);
        // setTimeout(() => setHighlightedLandmarkId(null), 1000); // Clear highlight after 1 second
      }
    }, [highlightedLandmarkId]);

    const handleRowClick = (id) => {
      // Setting highlighted landmark as null
      setHighlightedLandmarkId(id)
    };

    const handleMouseEnter = (id) => {
        setHoveredLandmark(id);
    };

    const handleMouseLeave = () => {
        setHoveredLandmark(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditLandmark = (id) => {
        onEditLandmark(id);
    };

    const handleDeleteLandmark = (id) => {
      console.log("Deleting the landmark id: ", id)
    }

    return (
        <Box sx={{ position: 'absolute', width: 'fit-content', top: '15vh', padding: '2px', backgroundColor: '#171818', border: '1px solid #e6e6e6', borderRadius: '4px', marginTop: '10px' }}>
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#ffffffdb' }} />}
                    aria-controls="added-landmarks-content"
                    id="added-landmarks-header"
                    sx={{ backgroundColor: '#171818', color: '#ffffffdb' }}
                >
                    <Typography variant="h8" color="#ffffffdb">Landmarks</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    <TableContainer component={Paper} sx={{ backgroundColor: '#171818', color: '#ffffffdb', maxHeight: '300px', overflow: 'auto' }}>
                        <Table size='small' aria-label="landmarks table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>ID</TableCell>
                                    <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Prim_X</TableCell>
                                    <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Prim_Y</TableCell>
                                    <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Sec_X</TableCell>
                                    <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Sec_Y</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mergedLandmarks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((landmark) => (
                                    <React.Fragment key={landmark.id}>
                                        <TableRow
                                            onClick={() => handleRowClick(landmark.id)}
                                            onMouseEnter={() => handleMouseEnter(landmark.id)}
                                            onMouseLeave={handleMouseLeave}
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor: hoveredLandmark === landmark.id || expandedRowId === landmark.id ? '#333333' : 'inherit',
                                                border: expandedRowId === landmark.id ? '2px solid #ffffffdb' : 'none'
                                            }}
                                        >
                                            <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.id}</TableCell>
                                            <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.prim_x}</TableCell>
                                            <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.prim_y}</TableCell>
                                            <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.sec_x}</TableCell>
                                            <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.sec_y}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                                <Collapse in={expandedRowId === landmark.id} timeout="auto" unmountOnExit>
                                                    <Box sx={{ margin: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Typography variant="h10" sx={{ color: '#FFFFFF', fontSize: '14px' }}>Options:</Typography>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => handleEditLandmark(landmark.id)}
                                                            sx={{ fontSize: '10px', height: '20px', marginLeft: 1, marginRight: 1, minWidth: '40px' }}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() => handleDeleteLandmark(landmark.id)}
                                                            sx={{ fontSize: '10px', height: '20px', minWidth: '40px' }}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={mergedLandmarks.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ backgroundColor: '#171818', color: '#ffffffdb' }}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
  };


  const LandmarkTable2 = ({ primaryLandmarks, secondaryLandmarks, expandedRowId, setExpandedRowId, highlightedLandmarkId, setHighlightedLandmarkId }) => {
    const [hoveredLandmark, setHoveredLandmark] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const formatCoordinate = (coordinate) => coordinate.toFixed(2);

    const mergedLandmarks = primaryLandmarks.map((primaryLandmark) => {
        const secondaryLandmark = secondaryLandmarks.find((landmark) => landmark.id === primaryLandmark.id);
        return {
            id: primaryLandmark.id,
            prim_x: formatCoordinate(primaryLandmark.x),
            prim_y: formatCoordinate(primaryLandmark.y),
            sec_x: secondaryLandmark ? formatCoordinate(secondaryLandmark.x) : '-',
            sec_y: secondaryLandmark ? formatCoordinate(secondaryLandmark.y) : '-'
        };
    });

    useEffect(() => {
      if (highlightedLandmarkId !== null) {
        const index = mergedLandmarks.findIndex((landmark) => landmark.id === highlightedLandmarkId);
        const newPage = Math.floor(index / rowsPerPage);
        console.log('Index: ', index)
        console.log("Page: ",newPage)
        setPage(newPage);
        setExpandedRowId(highlightedLandmarkId);
        // setTimeout(() => setHighlightedLandmarkId(null), 1000); // Clear highlight after 1 second
      }
    }, [highlightedLandmarkId]);

    const handleRowClick = (id) => {
      // Setting highlighted landmark as null
      setHighlightedLandmarkId(id)
    };

    const handleMouseEnter = (id) => {
        setHoveredLandmark(id);
    };

    const handleMouseLeave = () => {
        setHoveredLandmark(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditLandmark = (id) => {
        console.log("Handling edit landmark click")
        onEditLandmark(id);
    };

    const handleDeleteLandmark = (id) => {
      console.log("Deleting the landmark id: ", id)
    }

    return (
        <Box sx={{ padding: '0px', p: 1, overflow: 'auto', backgroundColor: '#0d0d0d'}}>
          <TableContainer component={Paper} sx={{ backgroundColor: '#171818', color: '#ffffffdb', maxHeight: '300px', overflow: 'auto' }}>
              <Table size='small' aria-label="landmarks table" stickyHeader>
                  <TableHead>
                      <TableRow>
                          <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>ID</TableCell>
                          <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Prim_X</TableCell>
                          <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Prim_Y</TableCell>
                          <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Sec_X</TableCell>
                          <TableCell sx={{ backgroundColor: '#171818', fontSize: '12px', color: '#ffffffdb' }}>Sec_Y</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {mergedLandmarks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((landmark) => (
                          <React.Fragment key={landmark.id}>
                              <TableRow
                                  onClick={() => handleRowClick(landmark.id)}
                                  onMouseEnter={() => handleMouseEnter(landmark.id)}
                                  onMouseLeave={handleMouseLeave}
                                  sx={{
                                      cursor: 'pointer',
                                      backgroundColor: hoveredLandmark === landmark.id || expandedRowId === landmark.id ? '#333333' : 'inherit',
                                      border: expandedRowId === landmark.id ? '2px solid #ffffffdb' : 'none'
                                  }}
                              >
                                  <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.id}</TableCell>
                                  <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.prim_x}</TableCell>
                                  <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.prim_y}</TableCell>
                                  <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.sec_x}</TableCell>
                                  <TableCell sx={{ fontSize: '11px', color: '#ffffffdb' }}>{landmark.sec_y}</TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                                      <Collapse in={expandedRowId === landmark.id} timeout="auto" unmountOnExit>
                                          <Box sx={{ margin: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                              <Typography variant="h10" sx={{ color: '#FFFFFF', fontSize: '14px' }}>Options:</Typography>
                                              <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => handleEditLandmark(landmark.id)}
                                                  sx={{ fontSize: '10px', height: '20px', marginLeft: 1, marginRight: 1, minWidth: '40px' }}
                                              >
                                                  Edit
                                              </Button>
                                              <Button
                                                  variant="contained"
                                                  color="secondary"
                                                  size="small"
                                                  onClick={() => handleDeleteLandmark(landmark.id)}
                                                  sx={{ fontSize: '10px', height: '20px', minWidth: '40px' }}
                                              >
                                                  Delete
                                              </Button>
                                          </Box>
                                      </Collapse>
                                  </TableCell>
                              </TableRow>
                          </React.Fragment>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={mergedLandmarks.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ backgroundColor: '#171818', color: '#ffffffdb' }}
          />
        </Box>
    );
  };


  
  

  useEffect(() => {
    console.log("Primary Landmarks Updated: ", primaryLandmarks);
    primaryLandmarksRef.current = primaryLandmarks;
  }, [primaryLandmarks]);

  useEffect(() => {
    console.log("Secondary Landmarks Updated: ", secondaryLandmarks);
    secondaryLandmarksRef.current = secondaryLandmarks;
  }, [secondaryLandmarks]);

  // Handler to toggle adding landmark mode
  const toggleAddingLandmark = () => {
    if (isEditModeEnabledRef.current) {
      alert("Edit Mode is enabled. Disable Edit Mode before adding landmarks.")
      return      
    }
    addingLandmarkRef.current = !addingLandmarkRef.current;
    console.log("Toggled addingLandmark to:", addingLandmarkRef.current);
    setUserInstruction("Instruction: Please Click on image to drop landmark points.");

    setAddingLandmark(addingLandmarkRef.current); // This assumes you've adjusted your state to just re-render the component
  };

  const primaryViewerRef = useRef(null);
  const secondaryViewerRef = useRef(null);

  const [userInstruction, setUserInstruction] = useState("Instruction: Navigate to sections with secondary data for registration");

  const secondaryRotationRef = useRef(0);

  const handleSecondaryRotationChange = (rotation) => {
    console.log("roation secondary: ",rotation)
    secondaryRotationRef.current = rotation;
    console.log("Secondary rotation: ", secondaryRotationRef.current)

  };


  

  // Corrected click handler
  const handlePrimaryViewerClick = ({ x, y }) => {
    console.log("Starting")
    console.log(addingLandmark)
    console.log("Editing mode: ",isEditModeEnabledRef.current)
    if (isEditModeEnabledRef.current) {
      if (!selectedPrimaryLandmarkForEditRef.current) {
        console.log("Please Select the landmark for editing before selecting location.")
        return
      }
      console.log("Selected Landmark: ",selectedPrimaryLandmarkForEditRef.current)
      // Retrieve the viewer instance from the ref
      const viewer = primaryViewerRef.current?.getViewer();
      if (viewer) {
        console.log("Viewer is available");

        // Update the landmark values
        console.log("Before updating the landmark: ", primaryLandmarksRef.current);
        updateLandmarks(selectedPrimaryLandmarkForEditRef.current.id, x, y, true);
        
        // Log the updated overlay reference
        console.log("Selected primary overlay for edit: ", selectedPrimaryOverlayForEditRef.current);
        
        // Updating the overlay position in the viewer
        viewer.updateOverlay(selectedPrimaryOverlayForEditRef.current, viewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(x, y)));

        console.log("Overlay updated");
      }
      else {
        console.log("Viewer is not available");
      }

      return
    }

    if (!addingLandmarkRef.current) {
      console.log("Not in adding landmark mode.");
      return;
    }

    console.log("Primary length: ", primaryLandmarksRef.current.length)
    console.log("Primary: ", primaryLandmarksRef)
    const newLandmark = { id: primaryLandmarksRef.current.length + 1, x, y };
    setPrimaryLandmarks((prevLandmarks) => {
      const updatedLandmarks = [...prevLandmarks, newLandmark];

      // Retrieve the viewer instance from the ref
      const viewer = primaryViewerRef.current?.getViewer();
      if (viewer) {
        console.log("Viewer is available");
        // Now, pass the actual viewer instance to addLandmarkOverlay
        addLandmarkOverlay(viewer, newLandmark, true);

        // Highlight and expand the corresponding row in the table
        const landmarkId = newLandmark.id;
        setExpandedRowId(landmarkId);
        setHighlightedLandmarkId(landmarkId)
      }
      else {
        console.log("Viewer is not available");
      }
      
      return updatedLandmarks;
    });
    console.log("Primary X position", x);
    console.log("Primary Y position", y);
    console.log("Primary Landmarks: ", primaryLandmarks)
  };

  // Similarly for the secondary viewer
  const handleSecondaryViewerClick = ({ x, y }) => {
    console.log("Starting")
    if (isEditModeEnabledRef.current) {
      console.log("Selected Landmark: ",selectedSecondaryLandmarkForEditRef.current)
      if (!selectedSecondaryLandmarkForEditRef.current) {
        console.log("Please Select the landmark for editing before selecting location.")
        return
      }
      // Popup confirmation
      // const isConfirmed = window.confirm("Place Landmark in new location?");
      // if (!isConfirmed) return;
      // Retrieve the viewer instance from the ref
      const viewer = secondaryViewerRef.current?.getViewer();
      if (viewer) {
        console.log("Viewer is available");
        // updating the landmark values
        updateLandmarks(selectedSecondaryLandmarkForEditRef.current.id, x, y, false)
        // Updating the overlay position on the viewer
        viewer.updateOverlay(selectedSecondaryOverlayForEditRef.current, viewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(x, y)));

        console.log("Secondary overlay updated")
      }
      else {
        console.log("Viewer is not available");
      }

      return

    }
    if (!addingLandmarkRef.current) {
      console.log("Not in adding landmark mode.");
      return;
    }

    console.log("Secondary length: ", secondaryLandmarksRef.current.length)
    console.log("Secondary: ", secondaryLandmarksRef)
    const newLandmark = { id: secondaryLandmarksRef.current.length + 1, x, y };
    setSecondaryLandmarks((prevLandmarks) => {
      const updatedLandmarks = [...prevLandmarks, newLandmark];

      // Retrieve the viewer instance from the ref
      const viewer = secondaryViewerRef.current?.getViewer();
      if (viewer) {
        console.log("Viewer is available");
        // Now, pass the actual viewer instance to addLandmarkOverlay
        addLandmarkOverlay(viewer, newLandmark, false);
        // Highlight and expand the corresponding row in the table
        const landmarkId = newLandmark.id;
        setExpandedRowId(landmarkId);
        setHighlightedLandmarkId(landmarkId)
      }
      else {
        console.log("Viewer is not available");
      }
      
      return updatedLandmarks;
    });
    console.log("Primary X position", x);
    console.log("Primary Y position", y);
    console.log("Secondary Landmarks: ", secondaryLandmarks)
    };

  // Function to add an SVG overlay as a marker
  const addLandmarkOverlay = (viewer, landmark, isPrimary) => {

    if (!viewer || typeof viewer.addOverlay !== 'function') {
        console.error('OpenSeadragon viewer not available');
        return;
    }

    let attr_id;
    if (isPrimary) {
        attr_id = 'primary-landmark-id';
    } else {
        attr_id = 'secondary-landmark-id';
    }

    console.log("Adding overlay");
    console.log(landmark.x);
    const svgNS = "http://www.w3.org/2000/svg";
    let overlay = document.createElementNS(svgNS, "svg");
    overlay.setAttribute("viewBox", "0 0 20 20");
    overlay.setAttribute("width", "20px");
    overlay.setAttribute("height", "20px");
    overlay.setAttribute("cursor", "pointer");
    overlay.setAttribute(attr_id, landmark.id);
    overlay.setAttribute('class', 'landmark-overlay');
    overlay.style.position = "absolute";
    overlay.style.cursor = "pointer";

    let circle = document.createElementNS(svgNS, "circle");
    circle.setAttribute("cx", "10"); // Circle's x coordinate
    circle.setAttribute("cy", "10"); // Circle's y coordinate
    circle.setAttribute("r", "10"); // Circle's radius
    circle.setAttribute("fill", "yellow"); // Circle's fill color
    circle.setAttribute("stroke", "black"); // Circle's stroke color
    overlay.appendChild(circle);

    let text = document.createElementNS(svgNS, "text");
    text.setAttribute("x", "10"); // Text's x coordinate
    text.setAttribute("y", "11"); // Text's y coordinate, adjusted for visual centering
    text.setAttribute("dominant-baseline", "middle");
    text.setAttribute("text-anchor", "middle"); // Ensure the text is centered on the circle
    text.setAttribute("font-size", "12px"); // Adjust as needed
    text.setAttribute("font-weight", "bold");
    text.setAttribute("fill", "black"); // Text color
    text.textContent = landmark.id.toString(); // Convert the id to a string
    overlay.appendChild(text);

    // Tooltip with Edit and Delete buttons
    let tooltip = document.createElement('div');
    tooltip.setAttribute('class', 'tooltip');
    tooltip.style.position = 'absolute';
    tooltip.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltip.style.color = 'white';
    tooltip.style.padding = '5px';
    tooltip.style.borderRadius = '5px';
    tooltip.style.display = 'none';

    let editButton = document.createElement('button');
    editButton.innerText = 'Edit';
    editButton.style.marginRight = '5px';
    editButton.style.color = 'white';
    editButton.style.fontSize = '10px';
    editButton.style.height = '20px';
    editButton.style.minWidth = '40px'
    editButton.style.background = '#1976d2'
    editButton.addEventListener('click', () => {
      console.log('Edit button clicked for landmark', landmark.id);
      // Handling edit landmark click action
      onEditLandmark(landmark.id)
    });

    let deleteButton = document.createElement('button');
    deleteButton.innerText = 'Delete';
    deleteButton.style.marginRight = '5px';
    deleteButton.style.color = 'white';
    deleteButton.style.fontSize = '10px';
    deleteButton.style.height = '20px';
    deleteButton.style.minWidth = '40px'
    deleteButton.style.background = '#9c27b0'
    deleteButton.addEventListener('click', () => {
      console.log('Delete button clicked for landmark', landmark.id);
      // Handling delete action
      onDeleteLandmark(landmark.id)
    });

    tooltip.appendChild(editButton);
    tooltip.appendChild(deleteButton);
    document.body.appendChild(tooltip);

    let isTooltipVisible = false;

    const showTooltip = (event) => {
        let rect = overlay.getBoundingClientRect();
        tooltip.style.left = `${rect.left + window.scrollX + 20}px`;
        tooltip.style.top = `${rect.top + window.scrollY}px`;
        tooltip.style.display = 'block';
        isTooltipVisible = true;
    };

    const hideTooltip = () => {
        tooltip.style.display = 'none';
        isTooltipVisible = false;
    };

    overlay.addEventListener('mouseenter', (event) => {
      console.log("Mouse enter")
        if (!isTooltipVisible) {
            circle.setAttribute("fill", "white");
            showTooltip(event);
        }
    });

    overlay.addEventListener('mouseleave', () => {
      console.log("Mouse leave")
        if (isTooltipVisible) {
            circle.setAttribute("fill", "yellow");
            hideTooltip();
        }
    });

    overlay.addEventListener('click', (event) => {
      console.log("Overlay clicked")
        event.stopPropagation();
        if (isTooltipVisible) {
            hideTooltip();
        } else {
            showTooltip(event);
        }
    });

    tooltip.addEventListener('mouseenter', () => {
        tooltip.style.display = 'block';
        isTooltipVisible = true;
    });

    tooltip.addEventListener('mouseleave', () => {
        hideTooltip();
        circle.setAttribute("fill", "yellow");
    });

    document.addEventListener('click', (event) => {
        if (isTooltipVisible && !overlay.contains(event.target) && !tooltip.contains(event.target)) {
            hideTooltip();
        }
    });

    viewer.addOverlay({
        element: overlay,
        location: viewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(landmark.x, landmark.y)),
        placement: OpenSeadragon.Placement.CENTER,
    });

    // const overlayTracker = new OpenSeadragon.MouseTracker({
    //     element: overlay,
    //     clickHandler: function(event) {
    //         console.log("Overlay clicked");
    //         if (isEditModeEnabledRef.current) {
    //             console.log("edit Mode is enabled");
    //             circle.setAttribute("fill", "orange");
    //             setUserInstruction("Please select a new location for the landmark");

    //             setSelectedLandmarkForEdit(landmark);
    //             selectedLandmarkForEditRef.current = landmark;

    //             setSelectedOverlayForEdit(overlay);
    //             selectedOverlayForEditRef.current = overlay;
    //         } else {
    //             const landmarkId = landmark.id;
    //             setExpandedRowId(landmarkId);
    //             setHighlightedLandmarkId(landmarkId);
    //         }
    //     },
    //     pressHandler: function(event) {
    //         console.log("Being pressed");
    //     },
    //     dragHandler: function(event) {
    //         console.log("Being dragged");
    //     }
    // }).setTracking(true);
  };




  const getPrimaryOverlay = (overlayId) => {
    const overlayElement = document.querySelector(`svg[primary-landmark-id='${overlayId}']`);

    if (!overlayElement) {
      console.error(`Overlay with ID ${overlayId} not found`);
      return null;
    }

    console.log("Selected Overlay: ", overlayElement)

    return overlayElement;
  };


  const getSecondaryOverlay = (overlayId) => {
    const overlayElement = document.querySelector(`svg[secondary-landmark-id='${overlayId}']`);

    if (!overlayElement) {
      console.error(`Overlay with ID ${overlayId} not found`);
      return null;
    }

    console.log("Selected Overlay: ", overlayElement)

    return overlayElement;
  };


  // const getSecondaryOverlay = (overlayId) => {

  //   // Extracting the primary Viewer element
  //   const viewer = secondaryViewerRef.current?.getViewer();

  //   if (!viewer || !viewer.currentOverlays) {
  //     console.error('OpenSeadragon viewer not available');
  //     return [];
  //   }

  //   const overlay = viewer.currentOverlays.find(
  //     overlay => overlay.element.getAttribute('data-landmark-id') === overlayId.toString()
  //   );
  
  //   if (!overlay) {
  //     console.error(`Overlay with ID ${overlayId} not found`);
  //     return null;
  //   }
  
  //   return overlay
  // };


  function updateOverlayPosition(overlay, landmark, viewer) {
    const overlayElement = overlay; // Your overlay element
    // First, remove the existing overlay to prevent duplicates
    // viewer.removeOverlay(overlayElement);
  
    // // Then, re-add the overlay at the new position
    // viewer.addOverlay({
    //   element: overlayElement,
    //   location: viewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(imagePosition.x, imagePosition.y)),
    //   placement: OpenSeadragon.Placement.CENTER,
    // });
    // Updating the visual marker on the viewer
    viewer.updateOverlay(overlay, viewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(landmark.x, landmark.y)));
  }

  function updateLandmarks(id, x, y, isPrimary) {
    console.log("Updating the Landmarks", primaryLandmarks)
    // Update the landmark position in your state
    // This function needs to handle updating the React state that holds the landmarks,
    // ensuring the state is correctly updated with the new x, y values for the moved landmark.
    const updateFunction = isPrimary ? setPrimaryLandmarks : setSecondaryLandmarks;
    updateFunction((prevLandmarks) => {
      return prevLandmarks.map((landmark) => {
        if (landmark.id === id) {
          return { ...landmark, x, y };
        }
        return landmark;
      });
    });
    console.log("After updating: ",primaryLandmarks)
    // primaryLandmarksRef.current = primaryLandmarks
  }

  let addedOverlays = new Set();

  // function showLandmarkOptionsPopper(event, landmarkId, isPrimary, overlay, viewer) {
  //   console.log("Showing options")
  //   console.log("event: ",event)
  //   console.log("event client x: ", event.originalEvent.clientX, event.originalEvent.clientY)
  //   // Prevent the default browser context menu from appearing
  //   // event.preventDefault();
  //   // Check if the overlay has already been added
  //   if (addedOverlays.has(landmarkId)) {
  //     console.log(`Overlay for landmark ${landmarkId} already added.`);
  //     return; // Prevent adding the overlay again
  //   }
    
  //   const svgNS = "http://www.w3.org/2000/svg";
    
  //   const existingMenu = document.querySelector('.landmark-context-menu');
  //   if (existingMenu) {
  //       existingMenu.remove();
  //   }

  //   // Create the context menu
  //   const menu = document.createElementNS(svgNS, "svg");
  //   menu.setAttribute("viewBox", "0 0 100 60"); // Adjust viewBox based on your needs
  //   menu.setAttribute("width", "100px");
  //   menu.setAttribute("height", "60px");
  //   menu.classList.add('landmark-context-menu');
  //   menu.style.cursor = "pointer";

  //   // Create and append SVG elements (e.g., rect for background, text for options)
  //   // Background
  //   let rect = document.createElementNS(svgNS, "rect");
  //   rect.setAttribute("width", "100");
  //   rect.setAttribute("height", "100");
  //   rect.setAttribute("fill", "#0d0d0d");
  //   menu.appendChild(rect);
  //   let closeBtn = document.createElementNS(svgNS, "text");
  //   closeBtn.setAttribute("x", "85"); // Position close to the top-right corner of the overlay
  //   closeBtn.setAttribute("y", "20"); // Adjust y position to ensure it's visibly inside the overlay
  //   closeBtn.setAttribute("fill", "#e3e2e2"); // Red color for visibility, adjust as needed
  //   closeBtn.setAttribute("font-size", "12"); // Set font size
  //   closeBtn.setAttribute("cursor", "pointer"); // Change cursor on hover to indicate interactiveness
  //   closeBtn.textContent = "X"; // Simple 'X' text as close button
  //   menu.appendChild(closeBtn);
  //   // Option Texts
  //   // Edit Option
  //   let editText = document.createElementNS(svgNS, "text");
  //   editText.setAttribute("x", "10");
  //   editText.setAttribute("y", "40");
  //   editText.setAttribute("fill", "#e3e2e2");
  //   editText.setAttribute("font-size", "12");
  //   editText.textContent = "Edit";
  //   menu.appendChild(editText);

  //   // Delete Option
  //   let deleteText = document.createElementNS(svgNS, "text");
  //   deleteText.setAttribute("x", "10");
  //   deleteText.setAttribute("y", "60");
  //   deleteText.setAttribute("fill", "#e3e2e2");
  //   deleteText.setAttribute("font-size", "12");
  //   deleteText.textContent = "Delete";
  //   menu.appendChild(deleteText);

  //   // Event listeners for the menu options
  //   closeBtn.addEventListener('click', function() {
  //     console.log("Close button clicked")
  //     viewer.removeOverlay(menu);
  //     addedOverlays.clear();
  //   });
  //   editText.addEventListener('click', function() {
  //       onEditLandmark(landmarkId);
  //   });
  //   deleteText.addEventListener('click', function() {
  //       onDeleteLandmark(landmarkId);
  //   });

  //   // Calculate position based on event.originalEvent (assuming it exists)
  //   // This should be adapted if using different event properties
  //   const point = new OpenSeadragon.Point(event.originalEvent.clientX, event.originalEvent.clientY);
  //   const viewportPoint = viewer.viewport.windowToViewportCoordinates(point);

  //   // Add the SVG menu as an overlay to the viewer
  //   viewer.addOverlay({
  //       element: menu,
  //       location: viewportPoint,
  //       placement: OpenSeadragon.Placement.CENTER
  //   });

  //   // Mark this overlay as added
  //   addedOverlays.add(landmarkId);

  // }


  function onEditLandmark(landmarkId) {
    
    // Implement the edit functionality
    console.log(`Editing landmark ${landmarkId}`);
    setUserInstruction("Instruction: Please click on new location to edit the landmark.");
    // Setting the Edit Mode 
    isEditModeEnabledRef.current = true
    setIsEditModeEnabled(isEditModeEnabledRef.current)
    console.log("Primary landmarks ref",primaryLandmarksRef.current)
    console.log("Primary landmarks: ",primaryLandmarks)
    // Find the primary landmark in the primaryLandmarks list
    const primLandmarkForEdit = primaryLandmarksRef.current.find(lm => lm.id === landmarkId);
    console.log("Primary Landmark for edit: ", primLandmarkForEdit)
    // Updating the ref for the primary landmark Edit
    setSelectedPrimaryLandmarkForEdit(primLandmarkForEdit)
    selectedPrimaryLandmarkForEditRef.current = primLandmarkForEdit
    console.log("Primary Landmark ref for edit: ", selectedPrimaryLandmarkForEditRef.current)
    // Get the corresponding primary overlay
    const primary_overlay = getPrimaryOverlay(landmarkId)
    console.log("Selected primary overlay: ", primary_overlay)
    setSelectedPrimaryOverlayForEdit(primary_overlay)
    selectedPrimaryOverlayForEditRef.current = primary_overlay
    console.log("Selected primary overlay Ref: ", selectedPrimaryOverlayForEditRef.current)
    
    // Find the secondary landmark in the secondaryLandmarks list
    const secLandmarkForEdit = secondaryLandmarksRef.current.find(lm => lm.id === landmarkId);
    // Updating the ref for the primary landmark Edit
    setSelectedSecondaryLandmarkForEdit(secLandmarkForEdit)
    selectedSecondaryLandmarkForEditRef.current = secLandmarkForEdit

    // Get the corresponding secondary overlay
    const secondary_overlay = getSecondaryOverlay(landmarkId)
    console.log("Selected secondary overlay: ", secondary_overlay)
    setSelectedSecondaryOverlayForEdit(secondary_overlay)
    selectedSecondaryOverlayForEditRef.current = secondary_overlay
    
  }

  function onFinishEditLandmark() {

    console.log("Primary Landmark being updated: ",selectedPrimaryLandmarkForEditRef.current)
    console.log("Primary landmarks: ",primaryLandmarksRef.current)
    // createPrimaryOverlays(primaryLandmarksRef.current)
    
    setUserInstruction("Instruction: Landmark Edited sucessfully")

    // Updating the selected landmark for edit
    setSelectedPrimaryLandmarkForEdit(null)
    selectedPrimaryLandmarkForEditRef.current = null

    // Updating the selected overlay for edit
    setSelectedPrimaryOverlayForEdit(null)
    selectedPrimaryOverlayForEditRef.current = null

    // Updating the selected landmark for edit
    setSelectedSecondaryLandmarkForEdit(null)
    selectedSecondaryLandmarkForEditRef.current = null

    // Updating the selected overlay for edit
    setSelectedSecondaryOverlayForEdit(null)
    selectedSecondaryOverlayForEditRef.current = null

    // Setting the Edit Mode 
    isEditModeEnabledRef.current = false
    setIsEditModeEnabled(isEditModeEnabledRef.current)
  }

  function onDeleteLandmark(landmarkId) {
    // Implement the delete functionality
    console.log(`Deleting landmark ${landmarkId}`);
    console.log("Primary Landmarks during deletion: ",primaryLandmarksRef.current)
    // Filter out the landmark to be deleted
    const updatedPrimaryLandmarks = primaryLandmarksRef.current.filter(landmark => landmark.id !== landmarkId);
    const updatedSecondaryLandmarks = secondaryLandmarksRef.current.filter(landmark => landmark.id !== landmarkId);

    // Update the indices of the remaining landmarks
    updatedPrimaryLandmarks.forEach((landmark, index) => {
      landmark.id = index + 1;
    });
    console.log('Updated landmarks: ',updatedPrimaryLandmarks)
    // Setting primary landmarks 
    setPrimaryLandmarks(updatedPrimaryLandmarks)
    // Updating the overlays
    createPrimaryOverlays(updatedPrimaryLandmarks)

    // Update the indices of the remaining landmarks
    updatedSecondaryLandmarks.forEach((landmark, index) => {
      landmark.id = index + 1;
    });
    console.log('Updated landmarks: ',updatedSecondaryLandmarks)
    // Setting primary landmarks 
    setSecondaryLandmarks(updatedSecondaryLandmarks)
    // Updating the overlays
    createSecondaryOverlays(updatedSecondaryLandmarks)
  }

  const [isAutoSyncEnabled, setIsAutoSyncEnabled] = useState(false);

  useEffect(() => {
    let cleanupFunction;
  
    if (isAutoSyncEnabled) {
      // Activate syncing
      cleanupFunction = setUpViewerSync();
    } else {
      // Deactivate syncing (if previously activated)
      if (cleanupFunction) {
        cleanupFunction();
      }
    }
  
    // Cleanup on component unmount or before re-running this effect
    return () => {
      if (cleanupFunction) {
        cleanupFunction();
      }
    };
  }, [isAutoSyncEnabled]); // Effect runs when isAutoSyncEnabled changes

  function reloadPrimaryOverlays () {
    // Retrieve the viewer instance from the ref
    const viewer = primaryViewerRef.current?.getViewer();
    viewer.clearOverlays()
    console.log("Reloading the primary landmarks")
    console.log("primary landmarks: ", primaryLandmarks)
    console.log("primary landmarks ref: ", primaryLandmarksRef.current)
    for (let landmark of primaryLandmarks) {
      console.log("Processing: ",landmark)
      addLandmarkOverlay(viewer, landmark, true) 
    }
  }

  function reloadSecondaryOverlays () {
    // Retrieve the viewer instance from the ref
    const viewer = secondaryViewerRef.current?.getViewer();
    if (!viewer) {
      console.log("Viewer is not initialized when trying to reload overlays.");
      return;
    }
    console.log("Reloading secondary landmarks:", secondaryLandmarks);
    // Clear existing overlays before re-adding them
    viewer.clearOverlays();

    console.log("Reloading the primary landmarks")
    console.log("secondary landmarks: ", secondaryLandmarks)
    console.log("Secondary landmark ref: ",secondaryLandmarksRef.current)
    secondaryLandmarks.forEach(landmark => {
        console.log("Processing landmark:", landmark);
        addLandmarkOverlay(viewer, landmark, false);
    });
  }

  function createPrimaryOverlays (primary_landmarks) {
    // Retrieve the viewer instance from the ref
    const viewer = primaryViewerRef.current?.getViewer();
    viewer.clearOverlays()
    console.log("Creating the primary landmarks",primary_landmarks)
    for (let landmark of primary_landmarks) {
      console.log("Processing: ",landmark)
      addLandmarkOverlay(viewer, landmark, true) 
    }
  }

  function createSecondaryOverlays (secondary_landmarks) {
    // Retrieve the viewer instance from the ref
    const viewer = secondaryViewerRef.current?.getViewer();
    if (!viewer) {
      console.log("Viewer is not initialized when trying to reload overlays.");
      return;
    }
    // Clear existing overlays before re-adding them
    viewer.clearOverlays();

    console.log("Reloading the primary landmarks")

    secondary_landmarks.forEach(landmark => {
        console.log("Processing landmark:", landmark);
        addLandmarkOverlay(viewer, landmark, false);
    });
  }

  
  
  function setUpViewerSync() {
    console.log("Syncing viewers")
    let primaryViewer = primaryViewerRef.current?.getViewer()
    let secondaryViewer = secondaryViewerRef.current?.getViewer()
    console.log("Primary Landmarks: ",primaryLandmarks)
    console.log("Secondary Landmarks: ",secondaryLandmarks)
    // Ensure both viewers and landmarks are loaded
    if (!primaryViewer || !secondaryViewer || primaryLandmarks.length === 0 || secondaryLandmarks.length === 0) {
        console.error("Viewers or landmarks not ready for sync.");
        return;
    }

    // Reset both viewers to a default view
    primaryViewer.viewport.goHome();
    secondaryViewer.viewport.goHome();

    // Assuming the landmarks are already sorted or the first is the one you want
    const primaryFirstLandmark = primaryLandmarks[0];
    const secondaryFirstLandmark = secondaryLandmarks[0];

    // Calculate initial offset between the landmarks in viewport coordinates
    const primaryPoint = primaryViewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(primaryFirstLandmark.x, primaryFirstLandmark.y));
    const secondaryPoint = secondaryViewer.viewport.imageToViewportCoordinates(new OpenSeadragon.Point(secondaryFirstLandmark.x, secondaryFirstLandmark.y));
    const offset = {
        x: secondaryPoint.x - primaryPoint.x,
        y: secondaryPoint.y - primaryPoint.y
    };


    // Function to sync viewers based on the primary viewer's actions
    const syncFromPrimaryToSecondary = () => {
        const bounds = primaryViewer.viewport.getBounds();
        const newBounds = new OpenSeadragon.Rect(bounds.x + offset.x, bounds.y + offset.y, bounds.width, bounds.height);
        secondaryViewer.viewport.fitBounds(newBounds, true);
    };

    // Function to sync viewers based on the secondary viewer's actions
    const syncFromSecondaryToPrimary = () => {
        const bounds = secondaryViewer.viewport.getBounds();
        const newBounds = new OpenSeadragon.Rect(bounds.x - offset.x, bounds.y - offset.y, bounds.width, bounds.height);
        primaryViewer.viewport.fitBounds(newBounds, true);
    };

    // Attach event listeners to sync viewers
    primaryViewer.addHandler('viewport-change', syncFromPrimaryToSecondary);
    secondaryViewer.addHandler('viewport-change', syncFromSecondaryToPrimary);

    // Return a cleanup function to remove event listeners
    return () => {
        primaryViewer.removeHandler('viewport-change', syncFromPrimaryToSecondary);
        secondaryViewer.removeHandler('viewport-change', syncFromSecondaryToPrimary);
    };
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar variant='dense'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Box sx={{ width: '100%' }}> */}
            <Typography variant="h7" noWrap component="div">
              Multimodal Registration
            </Typography>
            <Box sx={{ position: 'absolute', right: 0, width: '50%', height: '30%', zIndex: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', pointerEvents: 'none' }}>
              <Typography variant="h6" sx={{  fontSize:'14px', color:'#fdcd24' }}>
                {userInstruction}
              </Typography>
            </Box>
            {/* {isProgress && <LinearProgress />}
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer 
        variant="permanent" 
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#0d0d0d",
            color: "rgb(227 227 227)"
          }
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: "rgb(227 227 227)"}} /> : <ChevronLeftIcon sx={{ color: "rgb(227 227 227)"}} />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ color: "rgb(227 227 227)"}} />
        <List>
          {drawerItems.map((item, index) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => handleItemClick(item, index)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: "rgb(227 227 227)"
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: "rgb(227 227 227)"
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={<Typography variant="body2" style={{ fontSize: '13px' }}>{item.text}</Typography>} 
                  sx={{ opacity: open ? 1 : 0 }} 
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box sx={{ p: 2, color: "rgb(227 227 227)", padding: '0px' }}>
          {selectedTab !== null && drawerItems[selectedTab]?.tabContent}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', gap: '7px', justifyContent: 'space-around', height:'100vh', paddingTop:'6vh', backgroundColor: 'black',position: 'relative', transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }), }}>
        <Box sx={{ width: '100%', height:'100%', textAlign: 'center', paddingTop: '10px', paddingBottom: '5px' }}>
          <Box sx={{ height: '4%' }}>
            <Typography variant="h9" color="#ffffffdb" backgroundColor="#171818" gutterBottom>
              STP 3D Sample ( Fixed )
            </Typography>
            <FormControl size="small" sx={{ minWidth: 120, paddingLeft:'10px' }}>
              {/* <InputLabel id="select-channel-label-fixed">Select Channels</InputLabel> */}
              <Select
                labelId="select-channel-label-fixed"
                id="select-channel-fixed"
                value={selectedChannel}
                label="Select Channels"
                onChange={(e) => setSelectedChannel(e.target.value)}
                sx={{ height: 25, backgroundColor: '#f5f5f5' , fontSize:'12px', paddingLeft:'8px' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '.MuiMenuItem-root': {
                        fontSize: '11px', // Set the font size for menu items
                      },
                    },
                  },
                }}
              >
                {channelOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <OpenSeadragonPrimaryViewer
            ref={primaryViewerRef}
            tileSources={primaryImageTileSource}
            onClick={handlePrimaryViewerClick}
            landmarks={primaryLandmarks}
            onViewerReady={handlePrimaryViewerStatus}
            onRotationChange={handleSecondaryRotationChange}
            showRotationControl={false}
          />
          <Box sx={{ position: 'absolute', top: '8%', left: '1%', backgroundColor: 'rgb(245 241 241 / 0%)', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FormControlLabel
              control={<Checkbox sx={{ color: '#fff' }}
              checked={addingLandmarkRef.current}
              onChange={toggleAddingLandmark}
              />}
              label={<Typography sx={{ fontSize: '11px', color: '#fff' }}>Landmark Mode</Typography>}
              sx={{ my: 1, display: 'flex', alignItems: 'center', color: '#fff' }}
            />
          </Box>
          {addingLandmarkRef.current && (
            <Box sx={{ 
              position: 'absolute', 
              top: '15%', 
              left: '1%', 
              backgroundColor: 'rgb(0 0 0 / 65%)', 
              color: '#fff', 
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': {
                m: 1, 
                width: 'auto', // Change this to 'auto' or a specific value that fits better
                flexGrow: 1 // This makes the text field grow to fill the space
              },
              justifyContent: 'center', 
              alignItems: 'center' 
              }}>
              <Typography variant="body2" sx={{ marginBottom: 1, fontSize: '12px', textAlign: 'left' }}>Landmark Mode On</Typography>
              <Typography variant="body2" sx={{ marginBottom: 1, fontSize: '10px', color:'#df9812' }}>
                Please click on image to drop landmark points on corresponding regions.
              </Typography>
            </Box>
          )}
          {isEditModeEnabledRef.current && (
            <Box
              sx={{
                position: 'absolute',
                top: '15%',
                left: '1%',
                backgroundColor: 'rgb(33 33 33 / 75%)',
                color: '#fff',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '12px',
                borderRadius: 1,
                boxShadow: 3,
                borderColor: 'white'
              }}
            >
              <Typography variant="h15" sx={{ marginBottom: 1, fontSize: '14px'}}>
                Edit Mode On
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 1, fontSize: '11px' }}>
                Click on image to move Landmark
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: 1, fontSize: '12px', color:'#df9812' }}>
                Editing Landmark ID: {selectedPrimaryLandmarkForEditRef.current?.id}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => onFinishEditLandmark()}
                sx={{ fontSize: '10px' }}
              >
                Update Landmark 
              </Button>
            </Box>
          )}

          {/* Left-aligned icon bar */}
          {/* <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            left: 16, // Adjust for spacing from the right edge
            top: '50%', // Center vertically
            transform: 'translateY(-50%)', // Adjust for true vertical centering
            zIndex: 1300, // Ensure it's above the main content and drawer
            gap: 2, // Spacing between icons
          }}>
            {leftIcons.map((item, index) => (
            <Box
              key={index}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1, 
                position: 'relative',
                '&:hover div': {
                  display: 'flex', // Show the label on hover
              } }}
            >
              <IconButton
                key={index}
                sx={{ color: 'primary.contrastText', backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' } }}
                aria-label={item.name}
                onClick={item.onClick} 
              >
                {item.icon}
              </IconButton>
              <Box
                sx={{
                  display: 'none', // Hide the label by default
                  position: 'absolute',
                  left: '100%', // Position the label to the right of the icon
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'primary.main', // Match the icon button background or choose a different style
                  color: 'primary.contrastText',
                  padding: '0 8px', // Add some padding around the text
                  borderRadius: '4px', // Optional: style the label
                  marginLeft: '6px', // Space between icon and label
                  whiteSpace: 'nowrap'
                }}
              >
                <Typography variant="caption" style={{ fontSize: '12px' }}>{item.name}</Typography>
              </Box>
            </Box>
            ))}
          </Box> */}
        </Box>
        <Box sx={{ width: '100%', height:'100%', textAlign: 'center', paddingTop: '10px', paddingBottom: '5px' }}>
          <Box sx={{ height: '4%' }}>
            <Typography variant="h9" color="#ffffffdb" backgroundColor="#171818" gutterBottom>
              Secondary Section ( Moving )
            </Typography>
            <FormControl size="small" sx={{ minWidth: 120 , paddingLeft:'8px'}}>
                {/* <InputLabel id="select-channel-label-fixed">Select Channels</InputLabel> */}
                <Select
                  labelId="select-channel-label-fixed"
                  id="select-channel-fixed"
                  value={selectedSecondaryChannel}
                  label="Select Channels"
                  onChange={(e) => setSecondaryChannel(e.target.value)}
                  sx={{ height: 25, backgroundColor: '#f5f5f5' , fontSize:'12px' , paddingLeft:'8px' }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '.MuiMenuItem-root': {
                          fontSize: '11px', // Set the font size for menu items
                        },
                      },
                    },
                  }}
                >
                  {secondarychannelOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 120 , paddingLeft:'8px'}}>
                {/* <InputLabel id="select-channel-label-fixed">Select Channels</InputLabel> */}
                <Select
                  labelId="select-secondary-label-fixed"
                  id="select-secondary-fixed"
                  value={selectedSecondarysample}
                  label="Select Modality"
                  onChange={(e) => setSecondarySample(e.target.value)}
                  sx={{ height: 25, backgroundColor: '#f5f5f5' , fontSize:'12px' , paddingLeft:'8px' }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '.MuiMenuItem-root': {
                          fontSize: '11px', // Set the font size for menu items
                        },
                      },
                    },
                  }}
                >
                  {secondarySamplesList.map((option, index) => {
                    const displayText = option.split('_')[0]; // Split the string by underscore and use the first part
                    return (
                      <MenuItem key={index} value={option}>{displayText}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
          </Box>
          <OpenSeadragonSecondaryViewer
           ref={secondaryViewerRef}
           tileSources={secondaryImageTileSource}
           onClick={handleSecondaryViewerClick}
           landmarks={secondaryLandmarks}
           onViewerReady={handleSecondaryViewerStatus}
           onRotationChange={handleSecondaryRotationChange}
           showRotationControl={true}
          />
          {/* {addingLandmarkRef.current && (
            <Box sx={{ position: 'absolute', top: '12%', left: '53%', backgroundColor: 'rgb(245 241 241 / 0%)', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body2">Add Landmark Mode On</Typography>
            </Box>
          )} */}
          {/* {addingLandmarkRef.current && (
            <LandmarkTable 
              primaryLandmarks={primaryLandmarks} 
              secondaryLandmarks={secondaryLandmarks}
              expandedRowId={expandedRowId}
              setExpandedRowId={setExpandedRowId}
              highlightedLandmarkId={highlightedLandmarkId}
              setHighlightedLandmarkId={setHighlightedLandmarkId}
            />
          )} */}
        </Box>
        {/* <Box sx={{
          position: 'absolute',
          right: '6%',
          bottom: '61%',
          transform: 'translateY(-50%)',
          zIndex: 1300,
        }}>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon />}
            direction="up"
            sx={{ position: 'absolute'}}
          >
            {speedIcons.map((action, index) => (
              <SpeedDialAction
                key={index}
                icon={action.icon}
                tooltipTitle={<Typography variant="caption" style={{ fontSize: '12px' }}>{action.name}</Typography>}
                onClick={action.onClick}
              />
            ))}
          </SpeedDial>
        </Box> */}
        {/* Right-aligned icon bar */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'fixed',
          right: 16, // Adjust for spacing from the right edge
          top: '50%', // Center vertically
          transform: 'translateY(-50%)', // Adjust for true vertical centering
          zIndex: 1300, // Ensure it's above the main content and drawer
          gap: 2, // Spacing between icons
        }}>
          {speedIcons.map((item, index) => (
          <Box
            key={index}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1, 
              position: 'relative',
              '&:hover div': {
                display: 'flex', // Show the label on hover
            } }}
            >
            <IconButton
              key={index}
              sx={{ color: 'primary.contrastText', backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' } }}
              aria-label={item.name}
              onClick={item.onClick}
            >
              {item.icon}
            </IconButton>
            <Box
              sx={{
                display: 'none', // Hide the label by default
                position: 'absolute',
                right: '100%', // Position the label to the right of the icon
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'primary.main', // Match the icon button background or choose a different style
                color: 'primary.contrastText',
                padding: '0 8px', // Add some padding around the text
                borderRadius: '4px', // Optional: style the label
                marginRight: '6px', // Space between icon and label
                whiteSpace: 'nowrap'
              }}
            >
              <Typography variant="caption" style={{ fontSize: '12px' }}>{item.name}</Typography>
            </Box>
          </Box>
          ))}
        </Box>
        <Slider
            aria-label="Section Slider"
            value={section}
            onChange={(event, newValue) => setSection(newValue)}
            min={1}
            max={maxSections}
            valueLabelDisplay='on'
            valueLabelFormat={value => <div>Section: {value}</div>}
            sx={{
              position: 'absolute',
              bottom: 20, // Distance from the bottom
              left: '50%',
              transform: 'translateX(-50%)', // Center horizontally
              width: '80%', // Adjust as needed
              '& .MuiSlider-markLabel': {
                color: 'rgb(251 254 255 / 60%)', // Customize label color
                fontSize: '12px', // Smaller font size
                // transform: 'rotate(-45deg)', // Rotate the labels
              },
              '& .MuiSlider-mark': {
                backgroundColor: 'red', // Customize mark color
              }
            }}
            marks={marks.map((mark, index, arr) => {
              const isFarEnough = index === 0 || Math.abs(mark.value - arr[index - 1].value) > 10; // Adjust 10 based on the distance between marks
              return {
                ...mark,
                label: isFarEnough ? mark.label : '', // Only display label if far enough from the previous mark
              };
            })}
          />
      </Box>
      {/* Modal for Selecting landmarks Data */}
      <Popper 
        placement="left-start" 
        open={landmarkModalOpen} 
        anchorEl={anchorEl.current}
      >
        <Paper sx={{
          bgcolor: 'black', // Darker background
          p: 1,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid #ccc',
          zIndex: 1300,
          color: '#fff', // Light text for contrast
          marginLeft: '10px',
          width: '35vh'
        }}>
          <IconButton
            aria-label="close"
            onClick={handleLandmarkClick}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
              color: '#fff', // Adjust close button color for visibility
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ p: 1, overflow: 'auto', backgroundColor:'#0d0d0d' }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '15px',fontWeight:'500', color: '#fff', borderBottom: '1px solid #666' }}>
              Landmark Tools
            </Typography>
            {isProgress && <LinearProgress />}
            <FormControlLabel
              control={<Checkbox sx={{ color: '#fff' }}
              checked={addingLandmarkRef.current}
              onChange={toggleAddingLandmark}
              />}
              label={<Typography sx={{ fontSize: '10px', color: '#fff' }}>Enable Add Landmark</Typography>}
              sx={{ my: 1, display: 'flex', alignItems: 'center', color: '#fff' }}
            />

            <FormControlLabel
              control={<Checkbox sx={{ color: '#fff' }}
              checked={isAutoSyncEnabled}
              onChange={e => setIsAutoSyncEnabled(e.target.checked)}
              />}
              label={<Typography sx={{ fontSize: '10px', color: '#fff' }}>Auto Sync View</Typography>}
              sx={{ my: 1, display: 'flex', alignItems: 'center', color: '#fff' }}
            />
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff' }}>
              Import Landmarks
            </Typography>
            <Select
              fullWidth
              displayEmpty
              onChange={handleImportLandmarks}
              sx={{ height:25, mb: 1, color: '#fff', fontSize: '12px', backgroundColor: '#333', '.MuiOutlinedInput-notchedOutline': { borderColor: '#fff' } }}
            >
              {transformedDatasetNames.map(name => {
                const displayData = name.split('_')[0];
                return <MenuItem key={name} value={name} sx={{ fontSize: '12px' }}>{displayData}</MenuItem>
              })}
            </Select>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}>
              Smart Landmark Detection
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1, fontSize: '11px', color:'#df9812' }}>
                Choose a channel that depicts clear anatomical boundaries for better detection accuracy.
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              component="label"
              onClick={() => generateSmartLandmarks()}
              sx={{ 
                fontSize: '10px', 
                marginBottom: '10px'
              }}>
              Detect Landmarks
            </Button>
          </Box>
        </Paper>
      </Popper>

      {/* Modal for uploading the full resolution section to cloud */}
      <Popper 
        placement="left-start" 
        open={uploadModalOpen} 
        anchorEl={anchorUploadSection}  
      >
        <Paper sx={{
          bgcolor: 'black', // Darker background
          p: 1,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid #ccc',
          zIndex: 1300,
          color: '#fff', // Light text for contrast
          marginLeft:'10px',
          width:'35vh'
        }}>
          <IconButton
            aria-label="close"
            onClick={handleUploadClick}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
              color: '#fff', // Adjust close button color for visibility
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h7" component="h5" sx={{ m: 1 }}>
              Upload Secondary Section
            </Typography>
            {isProgress && <LinearProgress />}
          </Box>
          <Box sx={{ 
            p: 1, 
            overflow: 'auto',
            backgroundColor:'#0d0d0d' ,
            display: 'flex', // Adding flex to make children align in flex manner
            flexDirection: 'column', // Stack children vertically
            '& .MuiTextField-root': {
              m: 1, 
              width: 'auto', // Change this to 'auto' or a specific value that fits better
              flexGrow: 1 // This makes the text field grow to fill the space
            }
            }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}>
              Select Files From Local Directory
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 1, fontSize: '11px', color:'#df9812' }}>
                Choose all channels to upload for the section. Ensure each channel is saved as separate readable .TIF files.
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              component="label" 
              sx={{ 
                fontSize: '9px', 
                marginBottom: '10px',
                width:'16vh'
              }}>
              Browse Files
              <input 
                type="file" 
                hidden 
                multiple 
                onChange={handleUploadFileSelection}
              />
            </Button>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}>
              Select Modality of Secondary Data
            </Typography>
            <Select
              value={selectedUploadModality}
              onChange={(e) => onChangeUploadModality(e.target.value)}
              displayEmpty
              fullWidth
              sx={{ height: 25, backgroundColor: '#f5f5f5' , fontSize:'12px', marginBottom:'6px' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '.MuiMenuItem-root': {
                      fontSize: '12px', // Set the font size for menu items
                    },
                  },
                },
              }}
            >
              <MenuItem value="" disabled>Select Secondary Modality</MenuItem>
              <MenuItem value="MALDI" sx={{ fontSize: '10px' }}>MALDI</MenuItem>
              <MenuItem value="BF" sx={{ fontSize: '10px' }}>Brightfield</MenuItem>
              <MenuItem value="SIM" sx={{ fontSize: '10px' }}>SIM</MenuItem>
              <MenuItem value="IHC" sx={{ fontSize: '10px' }}>IHC</MenuItem>
              <MenuItem value="IBEX" sx={{ fontSize: '10px' }}>IBEX</MenuItem>
            </Select>
            {/* <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}>
              Select where to save section
            </Typography>
            <FormControlLabel
              control={
                <Radio
                  checked={uploadDatasetOption === 'new'}
                  onChange={() => setUploadDatasetOption('new')}
                  value="new"
                  name="uploadDatasetOption"
                  sx={{ color: '#fff' }}
                />
              }
              label={<Typography sx={{ fontSize: '11px', color: '#fff' }}>Create New Dataset</Typography>}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={uploadDatasetOption === 'existing'}
                  onChange={() => setUploadDatasetOption('existing')}
                  value="existing"
                  name="uploadDatasetOption"
                  color="primary"
                  sx={{ color: '#fff' }}
                />
              }
              label={<Typography sx={{ fontSize: '11px', color: '#fff' }}>Save in existing:</Typography>}
            />
            {uploadDatasetOption === 'existing' && (
              <Select
                onChange={handleUploadNameChange}
                fullWidth
                sx={{ height: 25, backgroundColor: '#f5f5f5' , fontSize:'12px', marginBottom:'6px' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '.MuiMenuItem-root': {
                        fontSize: '12px', // Set the font size for menu items
                      },
                    },
                  },
                }}
              >
                {uploadDatasetNames.map(name => {
                  const displayData = name.split('_')[0];
                  return <MenuItem key={name} value={name} sx={{ fontSize: '12px' }}>{displayData}</MenuItem>
                })}
              </Select>
            )} */}
            
            <Button variant="contained" color="primary" onClick={() => uploadToCloud()} sx={{ 
              flex: 1, 
              fontSize: '10px', 
              // marginRight: '8px' // Add margin right to only the first button
              }}>
              Upload Section To Cloud
            </Button>
            
          </Box>
        </Paper>
      </Popper>
      {/* Modal for performing registration */}
      <Popper
        placement="left-start"
        open={previewModalOpen}
        anchorEl={anchorPreview}
      >
        <Paper
          sx={{
            bgcolor: 'black', // Darker background
            p: 2,
            overflow: 'hidden',
            position: 'relative',
            border: '1px solid #ccc',
            zIndex: 1300,
            color: '#fff', // Light text for contrast
            width: '45vh',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handlePreviewClick}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
              color: '#fff', // Adjust close button color for visibility
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h7" component="h5" sx={{ mb: 2 }}>
              Image Registration & Preview
            </Typography>
            {isProgress && <LinearProgress />}
          </Box>

          {/* Registration and Metadata Input Section */}
          <Box
            sx={{
              p: 2,
              overflow: 'auto',
              backgroundColor: '#1a1a1a',
              display: 'flex',
              flexDirection: 'column',
              gap: 1, // Add spacing between form fields
            }}
          >
            {/* Primary (Fixed) Metadata Section */}
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}
            >
              Primary (Fixed) Metadata
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                id="fixed-pixel-size-x"
                label="X-Axis Pixel Size (microns)"
                value={fixedPixelSizeX}
                onChange={(e) => handleInputChange(e, setFixedPixelSizeX)}
                sx={{
                  fontSize: '10px',
                  width: '100%', // Use 100% width for flexibility
                  backgroundColor: '#0d0d0d',
                  '.MuiInputLabel-root': { color: '#aaa' },
                  '.MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': { borderColor: '#777' },
                    '&:hover fieldset': { borderColor: '#bbb' },
                  },
                  '.MuiOutlinedInput-input': { color: '#fff' },
                  '.MuiSvgIcon-root': { color: '#aaa' }
                }}
              />
              <TextField
                id="fixed-pixel-size-y"
                label="Y-Axis Pixel Size (microns)"
                value={fixedPixelSizeY}
                onChange={(e) => handleInputChange(e, setFixedPixelSizeY)}
                sx={{
                  fontSize: '10px',
                  width: '100%', // Use 100% width for flexibility
                  backgroundColor: '#0d0d0d',
                  '.MuiInputLabel-root': { color: '#aaa' },
                  '.MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': { borderColor: '#777' },
                    '&:hover fieldset': { borderColor: '#bbb' },
                  },
                  '.MuiOutlinedInput-input': { color: '#fff' },
                  '.MuiSvgIcon-root': { color: '#aaa' }
                }}
              />
            </Box>

            {/* Secondary (Moving) Metadata Section */}
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}
            >
              Secondary (Moving) Metadata
            </Typography>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                id="moving-pixel-size-x"
                label="X-Axis Pixel Size (microns)"
                value={movingPixelSizeX}
                onChange={(e) => handleInputChange(e, setMovingPixelSizeX)}
                sx={{
                  fontSize: '10px',
                  width: '100%', // Use 100% width for flexibility
                  backgroundColor: '#0d0d0d',
                  '.MuiInputLabel-root': { color: '#aaa' },
                  '.MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': { borderColor: '#777' },
                    '&:hover fieldset': { borderColor: '#bbb' },
                  },
                  '.MuiOutlinedInput-input': { color: '#fff' },
                  '.MuiSvgIcon-root': { color: '#aaa' }
                }}
              />
              <TextField
                id="moving-pixel-size-y"
                label="Y-Axis Pixel Size (microns)"
                value={movingPixelSizeY}
                onChange={(e) => handleInputChange(e, setMovingPixelSizeY)}
                sx={{
                  fontSize: '10px',
                  width: '100%', // Use 100% width for flexibility
                  backgroundColor: '#0d0d0d',
                  '.MuiInputLabel-root': { color: '#aaa' },
                  '.MuiOutlinedInput-root': {
                    color: '#fff',
                    '& fieldset': { borderColor: '#777' },
                    '&:hover fieldset': { borderColor: '#bbb' },
                  },
                  '.MuiOutlinedInput-input': { color: '#fff' },
                  '.MuiSvgIcon-root': { color: '#aaa' }
                }}
              />
            </Box>
          </Box>

          {/* Actions: Register, Preview, and Save */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              mt: 3,
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              sx={{ flex: 1, fontSize: '12px' }}
            >
              Register
            </Button>

            <Button
              onClick={togglePreview}
              variant="contained"
              color="secondary"
              sx={{ flex: 1, fontSize: '12px' }}
            >
              {previewVisible ? 'Hide Preview' : 'Show Preview'}
            </Button>

            <Button
              onClick={saveTransformation}
              variant="contained"
              color="success"
              sx={{ flex: 1, fontSize: '12px' }}
            >
              Save to Cloud
            </Button>
          </Box>
        </Paper>
      </Popper>

      {/* Modal for Saving the data on Cloud */}
      <Popper 
        placement="left-start" 
        open={saveModalOpen} 
        anchorEl={anchorSavePreview}  
      >
        <Paper sx={{
          bgcolor: 'black', // Darker background
          p: 1,
          overflow: 'hidden',
          position: 'relative',
          border: '1px solid #ccc',
          zIndex: 1300,
          color: '#fff', // Light text for contrast
          width: '40vh'
        }}>
          <IconButton
            aria-label="close"
            onClick={handleSaveClick}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
              color: '#fff', // Adjust close button color for visibility
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h7" component="h5" sx={{ m: 1 }}>
              Save Transformation
            </Typography>
            {isProgress && <LinearProgress />}
          </Box>
          <Box sx={{ 
            p: 1, 
            overflow: 'auto',
            backgroundColor:'#0d0d0d' ,
            display: 'flex', // Adding flex to make children align in flex manner
            flexDirection: 'column', // Stack children vertically
            '& .MuiTextField-root': {
              m: 1, 
              width: 'auto', // Change this to 'auto' or a specific value that fits better
              flexGrow: 1 // This makes the text field grow to fill the space
            }
            }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}>
              Select to Save Landmarks
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveLandmarks}
                  onChange={(e) => setSaveLandmarks(e.target.checked)}
                  sx={{ color: '#fff' }}
                />
              }
              label={<Typography sx={{ fontSize: '11px', color: '#fff' }}>Save Landmarks</Typography>}
            />
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, fontSize: '12px', color: '#fff', borderBottom: '1px solid #666' }}>
              Select where to save the data
            </Typography>
            <FormControlLabel
              control={
                <Radio
                  checked={datasetOption === 'new'}
                  onChange={() => setDatasetOption('new')}
                  value="new"
                  name="datasetOption"
                  sx={{ color: '#fff' }}
                />
              }
              label={<Typography sx={{ fontSize: '11px', color: '#fff' }}>Create New Dataset</Typography>}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={datasetOption === 'existing'}
                  onChange={() => setDatasetOption('existing')}
                  value="existing"
                  name="datasetOption"
                  color="primary"
                  sx={{ color: '#fff' }}
                />
              }
              label={<Typography sx={{ fontSize: '11px', color: '#fff' }}>Save in existing:</Typography>}
            />
            {datasetOption === 'existing' && (
              <Select
                onChange={handleTransformedNameChange}
                fullWidth
                sx={{ height: 25, backgroundColor: '#f5f5f5' , fontSize:'12px', marginBottom:'6px' }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '.MuiMenuItem-root': {
                        fontSize: '12px', // Set the font size for menu items
                      },
                    },
                  },
                }}
              >
                {transformedDatasetNames.map(name => {
                  const displayData = name.split('_')[0];
                  return <MenuItem key={name} value={name} sx={{ fontSize: '12px' }}>{displayData}</MenuItem>
                })}
              </Select>
            )}
            <Button variant="contained" color="primary" onClick={() => saveTransformation()} sx={{ 
              flex: 1, 
              fontSize: '11px', 
              // marginRight: '8px' // Add margin right to only the first button
              }}>
              Save to Cloud
            </Button>
          </Box>
        </Paper>
      </Popper>
      {/* Modal for Selecting STP Data */}
      <ImportModal
        modalOpen={modalOpen}
        handleCloseModal={handleCloseModal}
        projects={projects}
        datasets={datasets}
        importedSamples={importedSamples}
        isLoading={isLoading}
        handleProjectSelect={handleProjectSelect}
        selectedProject={selectedProject}
        handleSelectAllClick={handleSelectAllClick}
        isAllSelected={isAllSelected}
        selectedDatasets={selectedDatasets}
        handleDatasetSelectionChange={handleDatasetSelectionChange}
        handleImportSelected={handleImportSelected}
        importDataset={importDataset}
        getCleanDatasetName={getCleanDatasetName}
        fetchDatasetInfo={fetchDatasetInfo}
      />
    </Box>
  );
}

export default App;

